<template>
  <div class="cards-page">
    <div class="spinner-container" v-if="!doneInitialLoad">
      <b-spinner />
    </div>
    <div class="content" v-if="doneInitialLoad">
      <div class="controls">
        <BaseButton
          variant="transparent"
          :to="{ name: 'home' }"
          size="sm"
          class="nav-home"
        >
          <SVGIcon icon="chevron-left" />
          <span class="text">Home</span>
        </BaseButton>
        <div class="button-container">
          <BaseButton
            variant="light"
            class="tags"
            v-if="showTags"
            data-test="tag-menu-trigger"
            size="sm"
            @click="toggleTagMenuOpen($event)"
            aria-label="open tag menu"
          >
            <SVGIcon icon="tag" />
            <span class="text">Tags</span>
          </BaseButton>
          <TagMenu
            v-if="tagMenuOpen"
            :selectedTagIds="selectedTagIds"
            @tag-selected="toggleTagSelected"
            @close="toggleTagMenuOpen"
          />
          <b-button
            @click="toggleShowTagsPaginated"
            v-if="paginationEnabled && !shouldShowSampleCards"
            variant="light"
          >
            {{ showTags ? "Hide Tags" : "Show Tags" }}
          </b-button>
          <CardStateDropdown
            v-if="paginationEnabled && !shouldShowSampleCards"
            :currentCardsState="cardsFetchQuery.cardsState"
            :totalCards="totalCards"
            @card-state-change="cardStateChangeHandler"
          />
          <CardSortDropdown
            v-if="paginationEnabled && !shouldShowSampleCards"
            :currentCardsSortField="cardsFetchQuery.sortField"
            :currentCardsSortOrder="cardsFetchQuery.sortOrder"
            @card-sort-change="cardSortChangeHandler"
          />
          <b-dropdown
            variant="light"
            class="view"
            size="sm"
            no-caret
            right
            aria-label="card view menu"
            v-if="!paginationEnabled"
          >
            <template #button-content>
              <SVGIcon icon="eye" />
              <span class="text">View</span>
            </template>
            <b-dropdown-item @click="toggleShowTags">
              {{ showTags ? "Hide Tags" : "Show Tags" }}
            </b-dropdown-item>
            <b-dropdown-item
              @click="setState(CardState.OPEN)"
              :active="state === CardState.OPEN"
            >
              Open Cards
            </b-dropdown-item>
            <b-dropdown-item
              @click="setState(CardState.CLOSED)"
              :active="state === CardState.CLOSED"
            >
              Closed Cards
            </b-dropdown-item>
          </b-dropdown>
          <BaseButton
            variant="primary"
            size="sm"
            @click="openCreateCard"
            class="new-card"
            data-test="button-new-card"
          >
            {{ buttonText }}
          </BaseButton>
        </div>
      </div>
      <CardListComponent
        v-if="!paginationEnabled"
        :showTags="showTags"
        :selectedTagIds="selectedTagIds"
        :cardsProp="visibleCards"
        @click-card="inspectCard"
        :limitProp="16"
      />
      <CardListPaginated
        v-if="paginationEnabled"
        :loadingCardsPage="loadingCardsPage"
        :showTags="showTags"
        :selectedTagIds="selectedTagIds"
        :cardsProp="visibleCardsForCurrentPage"
        @click-card="inspectCard"
        :limitProp="cardsFetchQuery.cardsPageLimit"
        v-on="cardEventListeners"
      />
      <div
        class="loading-cards-spinner-container"
        v-if="loadingCardsPage"
        data-test="spinner-container"
      >
        <b-spinner />
      </div>
      <PrivacyStyledPagination
        v-if="displayPagination"
        align="right"
        v-model="cardsFetchQuery.currentCardsPage"
        :total-rows="totalCards"
        :per-page="cardsFetchQuery.cardsPageLimit"
        first-number
        last-number
      />
    </div>
    <CardInspector
      id="card-inspector"
      :cardProp="currentCard"
      @switch="switchCurrentCard"
      @start-onboarding="$bvModal.show('onboarding-modal')"
      v-on="cardEventListeners"
    />
    <CardCreate
      id="card-create"
      :type="newCardType"
      :category="newCardCategory"
      @create-success="handleCreateCardSuccess($event, true)"
    />
    <VaultedCardsModal
      id="vaulted-cards-modal"
      phase="active"
      :selectionLimit="cardCreateLimit"
      :canUpdatePlan="!!canUpdatePlan"
      @create-success="handleCreateCardSuccess($event, false)"
      @skip="$bvModal.show('edit-nickname')"
    />
    <SelectCardTypeModal @select="changeCardType" />
    <CategoryCardCreateModal
      id="category-card-create-modal"
      @select="createCategoryCard"
      @back="categoryCardGoBack"
    />
    <BaseModal id="onboarding-modal" class="onboarding-modal" static lazy>
      <OnboardingContainer
        @finished-onboarding="$router.push({ name: 'Home' })"
        :isSwitchingToAutomatic="isPendingManualPaymentsApproval"
      />
    </BaseModal>
  </div>
</template>
<script lang="ts">
import { Mixins, Component, Watch } from "vue-property-decorator";
import { AxiosError } from "@/lib/axios";
import CardListComponent from "@/components/CardList.vue";
import CardListPaginated from "@/components/CardListPaginated.vue";
import CardInspector from "@/components/CardInspector.vue";
import CardCreate from "@/components/CardCreate.vue";
import CategoryCardCreateModal from "@/components/card/CategoryCardCreateModal.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import TagMenu from "@/components/TagMenu.vue";
import VaultedCardsModal from "@/components/vaulted-cards/Modal.vue";
import {
  Card,
  CardList,
  CardSortField,
  CardSortOrder,
  CardState,
  CardType,
} from "@/types/Card";
import { Toast } from "@/mixins/Toast";
import { Tag } from "@/types/Tag";
import { EVENTS } from "@/types/Event";
import { FeatureFlags } from "@/types/LaunchDarkly";
import SelectCardTypeModal from "@/components/card/SelectCardTypeModal.vue";
import {
  cardStore,
  eventStore,
  tagStore,
  userStore,
  subscriptionStore,
  featureStore,
  merchantCategoriesStore,
} from "@/store";
import CardStateDropdown from "@/components/CardStateDropdown.vue";
import CardSortDropdown from "@/components/CardSortDropdown.vue";
import OnboardingContainer from "@/views/OnboardingContainer.vue";
import { MerchantCategory } from "@/types/MerchantCategory";
import PrivacyStyledPagination from "./shared/PrivacyStyledPagination.vue";

@Component({
  components: {
    CardListComponent,
    CardListPaginated,
    PrivacyStyledPagination,
    CardInspector,
    CardCreate,
    SVGIcon,
    TagMenu,
    VaultedCardsModal,
    SelectCardTypeModal,
    OnboardingContainer,
    CardStateDropdown,
    CardSortDropdown,
    CategoryCardCreateModal,
  },
})
export default class Cards extends Mixins(Toast) {
  doneInitialLoad = false;
  state: CardState = CardState.OPEN;
  showTags = !!this.$cookies.get("showTags");
  selectedTagIds: string[] = [];
  tagMenuOpen = false;
  currentCard: Card | null = null;
  CardState = CardState;
  newCardType: CardType | "" = "";
  newCardCategory: MerchantCategory | null = null;
  buttonText = "New Card"; // Default value

  totalCards = 0;

  cardsFetchQuery = {
    cardsState: cardStore.getters.defaultCardsState,
    currentCardsPage: 1,
    cardsPageLimit: cardStore.getters.defaultCardsPageLimit,
    sortField: cardStore.getters.defaultSortField,
    sortOrder: cardStore.getters.defaultSortOrder,
    cardsPageOffset: function () {
      return (this.currentCardsPage - 1) * this.cardsPageLimit;
    },
    tagList: [] as string[],
  };
  loadingCardsPage = false;
  paginationEnabled = false;

  get displayPagination() {
    return (
      this.paginationEnabled &&
      this.totalCards > this.cardsFetchQuery.cardsPageLimit
    );
  }

  get user() {
    return userStore.getters.currentUser;
  }

  get isPendingManualPaymentsApproval(): boolean {
    return !!userStore.getters.isPendingManualPaymentsApproval;
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  get canUpdatePlan(): boolean {
    const { plans } = subscriptionStore.getters;
    const priciestPlan = plans[plans.length - 1];
    return this.subscription?.subscriptionType !== priciestPlan?.type;
  }

  get cardCreateLimit(): number {
    return (
      this.subscription?.cardsPerMonth ||
      subscriptionStore.DEFAULT_CARD_CREATE_LIMIT
    );
  }

  get cardList(): CardList {
    return cardStore.getters.cardList({
      skipCache: true,
    });
  }

  get visibleCards(): Card[] {
    const { cardList } = this;
    const sampleCard = this.user?.sampleCard;

    if (this.state === CardState.CLOSED) {
      return cardList.closed;
    }

    if (!cardList.all.length && sampleCard) {
      if (!Array.isArray(sampleCard)) {
        return [sampleCard] as Card[];
      }

      return sampleCard;
    }

    return cardList.open;
  }

  get cardEventListeners() {
    if (this.paginationEnabled) {
      return {
        "card-paused": this.updateCardsPageView,
        "card-unpaused": this.updateCardsPageView,
        "card-closed": this.updateCardsPageView,
        "update-card": this.updateCardsPageView,
      };
    }
    return {};
  }

  get shouldShowSampleCards(): boolean {
    const sampleCard = this.user?.sampleCard;
    if (cardStore.getters.totalUserCards === 0 && sampleCard) {
      return true;
    }
    return false;
  }

  get visibleCardsForCurrentPage() {
    if (this.shouldShowSampleCards) {
      const sampleCard = this.user?.sampleCard;
      if (sampleCard) {
        if (!Array.isArray(sampleCard)) {
          return [sampleCard] as Card[];
        }
        return sampleCard;
      }
    }
    const { currentVisibleCards, totalCards } =
      cardStore.getters.currentCardsPageState;

    // Edge case where we have only one card on the last page
    // and we pause/unpause/close it, we need to go back a page
    // otherwise we'll be on an empty page without navigation controls
    if (
      this.cardsFetchQuery.currentCardsPage > 1 &&
      totalCards === this.cardsFetchQuery.cardsPageLimit
    ) {
      this.cardsFetchQuery = {
        ...this.cardsFetchQuery,
        currentCardsPage: this.cardsFetchQuery.currentCardsPage - 1,
      };
    }
    this.totalCards = totalCards;
    return currentVisibleCards;
  }

  @Watch("cardsFetchQuery", { deep: true })
  cardFetchQueryChangeHandler() {
    this.updateCardsPageView();
  }

  private updateQuery(
    cardsState: CardState,
    sortField: CardSortField,
    sortOrder: CardSortOrder,
    tagList: string[] = []
  ) {
    const currentCardsPage = 1;
    this.cardsFetchQuery = {
      ...this.cardsFetchQuery,
      cardsState,
      currentCardsPage,
      sortField,
      sortOrder,
      tagList,
    };
  }

  cardStateChangeHandler(state: CardState) {
    this.updateQuery(
      state,
      this.cardsFetchQuery.sortField,
      this.cardsFetchQuery.sortOrder,
      this.cardsFetchQuery.tagList
    );
  }

  cardSortChangeHandler(sortField: CardSortField, sortOrder: CardSortOrder) {
    this.updateQuery(
      this.cardsFetchQuery.cardsState,
      sortField,
      sortOrder,
      this.cardsFetchQuery.tagList
    );
  }

  updateCardsPageView() {
    this.loadingCardsPage = true;
    this.fetchCardsFromServer()
      .then(() => {
        this.loadingCardsPage = false;
      })
      .catch((error) => {
        if ((error as AxiosError)?.response?.status !== 401) {
          this.errorToast("Error loading Cards page.");
          this.$router.push({ name: "home" });
        }
      });
  }

  fetchCardsFromServer() {
    if (!this.paginationEnabled) {
      return cardStore.actions.getCards({
        skipCache: true,
      });
    }
    return cardStore.actions.getCards({
      limit: this.cardsFetchQuery.cardsPageLimit,
      offset: this.cardsFetchQuery.cardsPageOffset(),
      cardsState: this.cardsFetchQuery.cardsState,
      sortField: this.cardsFetchQuery.sortField,
      sortOrder: this.cardsFetchQuery.sortOrder,
      tagList: this.cardsFetchQuery.tagList,
      skipCache: true,
    });
  }

  async experimentCardCreateText() {
    try {
      const value = await featureStore.actions.fetchFlag({
        id: FeatureFlags.EXPERIMENT_CREATE_CARD_BUTTON_TEXT,
        force: true,
      });
      this.buttonText = value === "Create Card" ? "Create Card" : "New Card";
    } catch (error) {
      console.error("Error fetching button text:", error);
    }
  }

  async created() {
    try {
      const paginationValue = await featureStore.actions.fetchFlag({
        id: FeatureFlags.CARD_PAGE_UPDATES_V1,
      });

      this.paginationEnabled = paginationValue;
    } catch (error) {
      this.paginationEnabled = false;
    }

    this.loadingCardsPage = true;

    try {
      await Promise.all([
        this.fetchCardsFromServer(),
        tagStore.actions.fetchTags(),
        cardStore.actions.fetchTotalUserCards(),
        merchantCategoriesStore.actions.getMerchantCategories(),
        this.experimentCardCreateText(),
      ]);

      this.loadingCardsPage = false;
      this.doneInitialLoad = true;
    } catch (error) {
      if ((error as AxiosError)?.response?.status !== 401) {
        this.errorToast("Error loading Cards page.");
        this.$router.push({ name: "home" });
      }
    }
  }

  setState(state: CardState) {
    this.state = state || CardState.OPEN;
  }

  toggleTagMenuOpen(e: Event) {
    if (e) {
      e.stopPropagation();
    }
    this.tagMenuOpen = !this.tagMenuOpen;
  }

  toggleShowTags() {
    if (!this.showTags) {
      this.$cookies.set("showTags", true);
    } else {
      this.$cookies.remove("showTags");
    }
    this.showTags = !this.showTags;
  }

  toggleShowTagsPaginated() {
    if (!this.showTags) {
      this.$cookies.set("showTags", true);
    } else {
      this.$cookies.remove("showTags");
      this.selectedTagIds = [];
      this.updateQuery(
        this.cardsFetchQuery.cardsState,
        this.cardsFetchQuery.sortField,
        this.cardsFetchQuery.sortOrder,
        this.selectedTagIds
      );
    }
    this.showTags = !this.showTags;
  }

  toggleTagSelected(tag: Tag) {
    const selectedTagIdsCopy = [...this.selectedTagIds];
    if (selectedTagIdsCopy.includes(tag._id)) {
      this.selectedTagIds = selectedTagIdsCopy.filter((id) => {
        return id !== tag._id;
      });
    } else {
      selectedTagIdsCopy.push(tag._id);
      this.selectedTagIds = selectedTagIdsCopy;
    }
    if (this.paginationEnabled) {
      this.updateQuery(
        this.cardsFetchQuery.cardsState,
        this.cardsFetchQuery.sortField,
        this.cardsFetchQuery.sortOrder,
        this.selectedTagIds
      );
    }
  }

  clearSelectedTags() {
    this.selectedTagIds = [];
  }

  inspectCard(card: Card) {
    this.currentCard = card;
    this.$bvModal.show("card-inspector");
  }

  switchCurrentCard(cardID: number): void {
    const oldCard = cardStore.getters.getCard(cardID);
    if (oldCard) {
      this.currentCard = oldCard;
    }
  }

  // XXX(JH): More crude version of checks in Home.vue
  // No helpful error messaging, just send them to dashboard if they aren't
  // in a good state.
  openCreateCard(): void {
    this.newCardCategory = null;
    this.newCardType = "";

    const { user } = this;
    if (
      user?.hasHadAnyFundingSource &&
      !user?.chargeTermsAcceptTime &&
      user?.accountPurpose !== "BUSINESS" &&
      !user?.manualApplicationSentTime &&
      !user?.applicationDeclined &&
      !user?.hasManualPayments
    ) {
      eventStore.actions.record({
        name: EVENTS.CARD.CREATE_BLOCKED,
      });

      this.$router.push({
        name: "home",
      });
    } else {
      this.$bvModal.show("card-type-modal");

      eventStore.actions.record({
        name: EVENTS.CTA.CLICKED,
        data: {
          buttonContext: "Cards Page",
          buttonName: "Create Card",
        },
      });
    }
  }

  shouldShowTagRow() {
    return tagStore.getters.getTags.some((tag: Tag) => !tag.deleted);
  }

  categoryCardGoBack() {
    this.$bvModal.hide("category-card-create-modal");
    this.$nextTick(() => {
      this.$bvModal.show("card-type-modal");
    });
  }

  changeCardType(type: CardType | ""): void {
    this.newCardType = type;
    const newCardModalID =
      type === CardType.UNLOCKED ? "category-card-create-modal" : "card-create";
    this.$nextTick(() => this.$bvModal.show(newCardModalID));
  }

  createCategoryCard(category: MerchantCategory): void {
    this.newCardCategory = category;
    this.$bvModal.hide("category-card-create-modal");
    this.$nextTick(() => {
      this.$bvModal.show("card-create");
    });
  }

  handleCreateCardSuccess(card: Card, showInspector = true) {
    if (this.paginationEnabled) {
      this.updateCardsPageView();
    }
    this.$bvModal.hide("card-create");
    if (showInspector) {
      this.inspectCard(card);
    }
  }
}
</script>
<style lang="scss" scoped>
.cards-page {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  user-select: none;
  padding-bottom: 60px;

  > .spinner-container {
    position: relative;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
    min-height: calc(100vh - 60px);
  }

  > .content {
    > .controls,
    > .tag-row {
      padding: 0 20px;
      width: 1080px;
    }

    > .loading-cards-spinner-container {
      position: relative;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-bottom: 40px;
      min-height: calc(720px);
    }

    > .controls {
      display: flex;
      justify-content: space-between;
      margin: 35px auto;
      height: fit-content;
      box-shadow: none;
    }

    > .controls > .back {
      position: relative;
    }

    > .controls > .btn > .text,
    > .controls > .button-container > .btn > .text,
    > .controls > .button-container > .dropdown > .btn > .text {
      display: inline-block;
      margin: 0 5px 0 10px;
    }

    > .controls > .button-container {
      right: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: -10px;

      > .btn,
      > .dropdown {
        position: relative;
        margin-top: 10px;
        margin-left: 10px;
      }

      > .tag-menu {
        position: absolute;
        z-index: 999;
        top: 140px;
      }
    }

    > .tag-row {
      margin: 0 auto 35px;

      @media #{$media-width-820} {
        justify-content: center;
      }
    }

    > .card-list {
      width: 1080px;
      margin: 0 auto;
      justify-content: flex-start;
      transition-property: width;
      transition-duration: $duration-short;

      ::v-deep ._card {
        margin: 0 15px 30px;
      }
    }

    > .new-card {
      margin: 10px auto 30px;
      width: 240px;
    }

    @media #{$media-width-1110} {
      > .card-list,
      > .controls,
      > .tag-row,
      .pagination-container {
        width: 810px;
      }
    }

    @media #{$media-width-820} {
      .nav-home {
        display: none;
      }

      .button-container {
        flex-direction: column;
        width: 100%;
      }

      > .card-list {
        width: 540px;

        ::v-deep ._card {
          margin: 0 15px 30px;
        }
      }

      > .controls,
      > .tag-row,
      .pagination-container {
        width: 100%;
      }
    }

    @media #{$media-width-600} {
      > .card-list {
        justify-content: center;
        width: 280px;
      }
    }
  }
}

::v-deep {
  .commercial-charge-reissued-reminder,
  .commercial-charge-closed-reminder,
  .onboarding-modal {
    .modal-dialog {
      max-width: fit-content;
    }
  }

  .onboarding-modal {
    .modal-body {
      padding: 0;
    }
  }
}
</style>
