<template>
  <SimplePage
    :fullpage="false"
    :class="{
      'billing-container d-flex flex-column mt-5 justify-content-start':
        fullpage,
    }"
  >
    <router-link
      to="/select-plan"
      class="option-link mx-auto mt-1 mb-3"
      v-if="fullpage && !purchaseSuccess"
      :fullpage="false"
    >
      <SVGIcon icon="arrow-left" /> All Options
    </router-link>
    <div
      :class="{
        'billing d-flex flex-column mx-auto p-4': fullpage,
      }"
    >
      <div
        v-if="purchaseSuccess"
        class="d-flex flex-column flex-gap success align-items-center text-center"
      >
        <SVGIcon icon="tick-green" />
        <p class="mb-0" v-if="planWasChanged">
          You have successfully purchased the <br />
          Privacy {{ subscribingPlan?.name + " Plan" }}.
        </p>
        <p class="mb-0" v-else>
          Billing information updated for your <br />
          Privacy {{ subscribingPlan?.name + " Plan" }}.
        </p>
        <BaseButton
          variant="primary"
          class="mb-0 mt-0 purchase-button"
          :to="{ name: 'account' }"
          block
          size="md"
        >
          Go To My Account
        </BaseButton>
      </div>
      <BaseForm
        v-else
        @submit="submit"
        :fullpage="fullpage && !purchaseSuccess"
        :class="['signup-step -billing', { '-loading': waiting }]"
        data-test="billing"
      >
        <h1 v-if="billingRequired" class="title">Update billing info</h1>
        <div class="d-flex flex-column flex-gap" v-else>
          <div class="plan-name font-weight-bold">
            <span class="p-1">{{ subscribingPlan?.name + " Plan" }}</span>
          </div>
          <div class="plan-price">
            <DollarAmount
              :amount="subscribingPlan?.amount"
              :show-cents="false"
              class="d-inline-block font-weight-bold"
            />
            / mo.
          </div>
        </div>
        <b-spinner v-if="preloading" class="m-auto" />
        <div v-else>
          <p class="blurb mt-0">
            You'll be billed ${{ planAmount }} per month on the 1st of each
            month. Your monthly bill will be charged to your
            <span v-if="defaultFundingSelected && !newCard">
              default funding source.
            </span>
            <span v-else-if="!newCard">
              existing connected subscription funding card.
            </span>
            <span v-else> new subscription funding card. </span>
          </p>

          <div class="divider mb-4"></div>

          <b-alert variant="danger" :show="errorMessage.length > 0">
            {{ errorMessage }}
          </b-alert>

          <b-alert variant="info" :show="user.verificationNeeded">
            <strong>Note:</strong> Once you subscribe, your account will need to
            be reviewed by a member of our team before you can start creating
            cards. This step usually takes less than 48 hours, and you won't be
            charged until your account is activated.
          </b-alert>

          <b-alert variant="info" :show="freeMonthsNote.length > 0">
            <strong>Promo Note:&nbsp;</strong>{{ freeMonthsNote }}
          </b-alert>

          <b-dropdown
            v-if="!newCard"
            class="mb-2"
            no-caret
            block
            :toggle-attrs="{
              'aria-label': 'Select a funding source',
            }"
            variant="false"
            :disabled="disabledDropwdown"
            data-test="funding-source"
          >
            <template #button-content>
              <FundingPreview
                :source="
                  defaultFundingSelected ? defaultFundingSource : existingCard
                "
              />
            </template>
            <b-dropdown-item
              v-if="defaultFundingSource"
              :active="defaultFundingSelected"
              @click="selectedFundingSource = fundingType.DEFAULT"
            >
              <FundingPreview :source="defaultFundingSource" />
            </b-dropdown-item>
            <b-dropdown-item
              v-if="existingCard"
              :active="!defaultFundingSelected"
              @click="selectedFundingSource = fundingType.CARD"
            >
              <FundingPreview :source="existingCard" />
            </b-dropdown-item>
          </b-dropdown>
          <div v-else>
            <BaseInput
              class="mt-2"
              name="pan"
              label="Debit Card Number"
              placeholder="0000 0000 0000 0000"
              v-model="cardData.pan"
              type="text"
              maxlength="19"
              :state="errors.pan ? false : null"
              data-test="pan"
            />

            <div class="d-flex justify-content-between flex-gap">
              <BaseInput
                class="mt-2"
                name="exp"
                label="Expiration"
                placeholder="MM/YY"
                v-model="cardData.exp"
                type="text"
                maxlength="5"
                :state="errors.exp ? false : null"
                data-test="exp"
              />

              <BaseInput
                class="mt-2"
                name="cvv"
                label="CVV"
                placeholder=""
                v-model="cardData.cvv"
                type="text"
                maxlength="3"
                :state="errors.cvv ? false : null"
                data-test="cvv"
              />
            </div>
            <BaseInput
              class="mt-2"
              name="zip"
              label="Billing ZIP Code"
              placeholder="00000"
              v-model="cardData.zip"
              type="text"
              maxlength="10"
              :state="errors.zip ? false : null"
              data-test="zip"
            />
          </div>

          <BaseButton
            variant="primary"
            class="mb-3 mt-3 purchase-button"
            type="submit"
            block
            size="md"
            :loading="waiting"
            data-test="button-submit-billing"
            @click="trackPrimaryClick"
          >
            {{ ctaText }}
          </BaseButton>

          <div class="divider mb-3"></div>

          <BaseButton
            v-if="!newCard"
            variant="light"
            block
            size="md"
            @click.prevent="useNewCard"
            data-test="button-use-new-card"
          >
            Use a New Card
          </BaseButton>
          <BaseButton
            v-else-if="existingCard || defaultFundingSource"
            variant="light"
            block
            size="md"
            @click.prevent="useExistingSource"
            data-test="button-use-default"
          >
            Use existing funding source
          </BaseButton>
        </div>
      </BaseForm>
    </div>
    <BaseButton
      v-if="fullpage && !purchaseSuccess"
      variant="text"
      @click="back"
      block
      class="mt-2"
      size="sm"
    >
      Cancel
    </BaseButton>
    <div v-if="fullpage" class="footer container-lg text-center mt-5 mb-5 p-4">
      The Privacy card is issued by Patriot Bank, N.A., pursuant to licenses
      from Mastercard® International Incorporated and Visa U.S.A. Inc. and may
      be used everywhere Mastercard and Visa are accepted. Mastercard is a
      registered trademark of Mastercard International.
    </div>
  </SimplePage>
</template>

<script lang="ts">
import { formatDate, getDate } from "@/lib/dates";
import { Component, Vue, Prop } from "vue-property-decorator";
import { BAlert, BSpinner } from "bootstrap-vue";
import FundingPreview from "@/components/FundingPreview.vue";
import { CardValue } from "@/types/Form";
import {
  fundingStore,
  subscriptionStore,
  userStore,
  eventStore,
} from "@/store";
import { User } from "@/types/User";
import {
  card as cardValidator,
  cvc as cvvValidator,
  expiration as expValidator,
} from "creditcards";
import {
  CreateFundingCard,
  FundingType,
  V2FundingSource,
} from "@/types/Funding";
import {
  SubscriptionFundingType,
  SubscriptionState,
} from "@/types/Subscription";
import SVGIcon from "@/components/SVGIcon.vue";
import DollarAmount from "@/components/DollarAmount.vue";
import { EVENTS } from "@/types/Event";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

interface BillingCard extends CardValue {
  zip: string;
}

const DEFAULT_ERRORS = {
  pan: false,
  cvv: false,
  exp: false,
  zip: false,
};

@Component({
  components: {
    BAlert,
    BSpinner,
    FundingPreview,
    SVGIcon,
    DollarAmount,
  },
})
export default class Billing extends Vue {
  @Prop({ default: false }) fullpage!: boolean;
  newCard = false;
  errors = { ...DEFAULT_ERRORS };
  submitted = false;
  waiting = false;
  preloading = true;
  errorMessage = "";
  cardData: BillingCard = {
    pan: "",
    cvv: "",
    exp: "",
    zip: "",
  };
  selectedFundingSource: SubscriptionFundingType | null = null;
  purchaseSuccess = false;
  planWasChanged = false;

  get disabledDropwdown() {
    return (
      !(this.defaultFundingSource && this.existingCard) ||
      this.defaultFundingSource?.uuid === this.existingCard?.uuid
    );
  }

  get defaultFundingSelected() {
    return this.selectedFundingSource === SubscriptionFundingType.DEFAULT;
  }

  get fundingType() {
    return SubscriptionFundingType;
  }

  get user() {
    return userStore.getters.currentUser as User;
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  get planAmount() {
    return (this.subscribingPlan?.amount || 0) / 100;
  }

  get isPaidSubscription() {
    return subscriptionStore.getters.isPaidSubscription;
  }

  get ctaText() {
    return this.isPaidSubscription && !this.isChangingPlan
      ? "Update billing info"
      : `Purchase ${this.subscribingPlan?.name}`;
  }

  get billingRequired() {
    return this.isPaidSubscription && !this.isChangingPlan;
  }

  get selectedPlan() {
    return subscriptionStore.getters.selectedPlan();
  }

  get activePlan() {
    return subscriptionStore.getters.currentPlan;
  }

  get subscribingPlan() {
    return this.selectedPlan || this.activePlan;
  }

  get isChangingPlan() {
    return (
      this.selectedPlan &&
      (this.selectedPlan.uuid !== this.activePlan?.uuid ||
        (this.selectedPlan?.uuid === this.activePlan?.uuid &&
          this.subscription?.state === SubscriptionState.CANCELLED))
    );
  }

  get freeMonthsNote() {
    const freeMonths = this.subscribingPlan?.freeMonths;

    if (!this.isChangingPlan || !freeMonths) {
      return "";
    }

    const firstChargeMoment = getDate()
      .add(freeMonths + 1, "months")
      .startOf("month");
    const firstChargeDate = formatDate("MMMM Do, YYYY", firstChargeMoment);

    return `You get ${freeMonths} months free  for signing up through ${this.subscribingPlan?.name}. Your first charge will be on ${firstChargeDate}.`;
  }

  get defaultFundingSource() {
    return fundingStore.getters.default;
  }

  get subscriptionCards() {
    return fundingStore.getters.subscriptionCards;
  }

  get existingCard() {
    let card = this.subscriptionCards.find(
      (subscriptionCard: V2FundingSource) =>
        subscriptionCard.uuid === this.subscription?.fundingUuid
    );

    if (!card) {
      card = this.subscriptionCards.sort(
        (a: V2FundingSource, b: V2FundingSource) => {
          return new Date(b.created).getTime() - new Date(a.created).getTime();
        }
      )[0];
    }

    if (!card && this.defaultFundingSource?.type === FundingType.CARD) {
      card = {
        ...this.defaultFundingSource,
        default: false,
      };
    }

    return card;
  }

  async created() {
    await Promise.all([
      fundingStore.actions.fetchFundingSources(),
      subscriptionStore.actions.fetchPlans(),
      userStore.actions.getCurrentUser(),
    ]);

    if (!this.isChangingPlan && !this.isPaidSubscription) {
      return this.$router.replace({ name: "subscription-plan" });
    }

    // It's possible for a subscription to be cancelled and be attached to a deleted funding
    // source which means we need to be explicit about the funding source we want to use.
    if (
      this.existingCard &&
      this.subscription?.fundingType === SubscriptionFundingType.CARD
    ) {
      this.selectedFundingSource = SubscriptionFundingType.CARD;
    } else if (
      this.subscription?.fundingType === SubscriptionFundingType.DEFAULT
    ) {
      this.selectedFundingSource = SubscriptionFundingType.DEFAULT;
    } else if (this.defaultFundingSource && !this.existingCard) {
      this.selectedFundingSource = SubscriptionFundingType.DEFAULT;
    } else {
      this.newCard = true;
    }
    eventStore.actions.record({
      name: EVENTS.SUBSCRIPTION.BILLING_VIEWED,
      data: { plan: this.subscribingPlan?.name },
    });
    this.preloading = false;
  }

  resetErrors() {
    this.errors = { ...DEFAULT_ERRORS };
    this.errorMessage = "";
  }

  handlePanError(error: string) {
    this.errorMessage = error;
    this.errors.pan = !!error;
  }

  validateCard(): false | CreateFundingCard {
    this.resetErrors();

    const pan = cardValidator.parse(this.cardData.pan);

    if (cardValidator.isValid(pan, "American Express")) {
      this.handlePanError(
        "We're sorry, we do not support American Express cards"
      );

      return false;
    }

    if (!cardValidator.isValid(pan)) {
      this.handlePanError("Please enter a valid card number");

      return false;
    }

    if (!this.cardData.exp) {
      this.errorMessage = "Please enter your card's expiration date";
      this.errors.exp = true;

      return false;
    }

    const currentCenturyStart = new Date()
      .getFullYear()
      .toString()
      .substring(0, 2);
    const [expMonth, expYear] = this.cardData.exp.split("/");
    const numMonth = parseInt(expMonth, 10);
    const numYear = parseInt(expYear ? currentCenturyStart + expYear : "0", 10);

    if (
      !expValidator.month.isValid(numMonth) ||
      !expValidator.year.isValid(numYear) ||
      expValidator.isPast(numMonth, numYear)
    ) {
      this.errorMessage = "Please enter your card's expiration date";
      this.errors.exp = true;

      return false;
    }

    if (!cvvValidator.isValid(this.cardData.cvv)) {
      this.errorMessage = "Please enter your card's CVV";
      this.errors.cvv = true;

      return false;
    }

    if (!this.cardData.zip || this.cardData.zip.length < 5) {
      this.errorMessage = "Please enter a valid ZIP code";
      this.errors.zip = true;

      return false;
    }

    return {
      cvv: this.cardData.cvv,
      zip: this.cardData.zip,
      pan,
      expMonth,
      expYear: numYear.toString(),
      isSubscription: true,
    };
  }

  trackPrimaryClick() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BILLING)} Primary CTA`,
    });
  }

  async submit() {
    this.waiting = true;

    let data: Record<string, any> = {};

    if (this.newCard) {
      const submittingCard = this.validateCard();

      if (!submittingCard) {
        this.waiting = false;

        return;
      }

      try {
        const fundingCard =
          await fundingStore.actions.createFundingCard(submittingCard);

        data = {
          subscriptionFundingType: SubscriptionFundingType.CARD,
          fundingCardUuid: fundingCard.uuid,
        };
      } catch (error) {
        const errData = (error as any).response?.data;
        let message = "Unable to create funding card";

        if (errData?.message && !errData?.errors) {
          message = errData.message;
        } else if (errData?.errors?.length) {
          message += " - " + errData.errors.join(", ");
        }

        this.errorMessage = message;
        this.waiting = false;

        return;
      }
    } else if (this.defaultFundingSelected) {
      data = {
        subscriptionFundingType: SubscriptionFundingType.DEFAULT,
      };
    } else {
      data = {
        subscriptionFundingType: SubscriptionFundingType.CARD,
        fundingCardUuid: this.existingCard!.uuid,
      };
    }

    try {
      if (this.isChangingPlan) {
        await subscriptionStore.actions.createSubscription({
          ...data,
          subscriptionTypeUuid: this.subscribingPlan!.uuid,
        });

        this.planWasChanged = true;
      } else {
        await subscriptionStore.actions.editSubscription({
          ...data,
          subscriptionUuid:
            subscriptionStore.getters.subscription!.subscriptionUuid,
        });
      }
    } catch (error) {
      this.errorMessage =
        (error as any).response?.data.message || "Unable to update billing";

      this.waiting = false;
      return;
    }

    subscriptionStore.actions.removeTemporaryPlanCookie();
    this.waiting = false;
    this.purchaseSuccess = true;

    this.$piwik.trackContentImpression(
      `${onboardingStepName(OnboardingSteps.BILLING)} Success`,
      this.planWasChanged ? "Purchase Plan" : "Update Billing Info"
    );
  }

  useNewCard() {
    this.resetErrors();

    this.newCard = true;

    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BILLING)} Use New Card CTA`,
    });
  }

  useExistingSource() {
    this.resetErrors();

    this.newCard = false;

    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BILLING)} Use Existing Funding CTA`,
    });
  }

  back() {
    this.$piwik.trackClick({
      name: `${onboardingStepName(OnboardingSteps.BILLING)} Cancel CTA`,
    });

    this.$router.back();
  }
}
</script>

<style lang="scss" scoped>
@import "./business-info-base";
.billing-container {
  background: #f0f0f5;
  min-height: calc(100vh - 48px);
  padding-top: 48px;
}

.success {
  min-width: 350px;
  color: #1f995c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.billing {
  max-width: 400px;
  background: white;
  border-radius: 16px;
  gap: 24px;
  box-shadow:
    0px 2px 8px 0px rgba(0, 0, 0, 0.08),
    0px 0px 0px 1px rgba(0, 0, 0, 0.08);
}

.option-link {
  font-size: 14px;
  color: $gray-800;
}

.plan-name {
  span {
    border-radius: 4px;
    background: #000000;
    color: #ffffff;
    font-size: 11px;
    line-height: normal;
    letter-spacing: 1.1px;
    text-transform: uppercase;
  }
}

.plan-price {
  font-size: 24px;
}

form.-billing {
  gap: 24px;
  padding-bottom: 0px;
}

::v-deep .form.fullpage::after {
  content: none;
}

.divider {
  width: 100%;
  height: 1px;
  background: $gray-300;
}

.purchase-button {
  background: $brand-purple;
}

.blurb {
  border-radius: 8px;
  background: rgba(48, 144, 241, 0.1);
  padding: 16px;
  color: #323242 !important;
}

.flex-gap {
  gap: 24px;
  label {
    flex: 1 1 0px;
  }
}

.funding-preview,
.funding-card-preview {
  height: 74px;
  background-color: white !important;
  ::v-deep .funding-name {
    font-weight: 600 !important;
  }
}

.b-dropdown ::v-deep {
  .btn.disabled {
    opacity: 1;
  }

  .btn.dropdown-toggle {
    padding: 0;
    border: none;
    background: initial;
    font-weight: initial;
    text-align: left;
  }

  .dropdown-menu {
    right: 0;
    padding: 0;
    overflow: hidden;

    li + li {
      border-top: 1px solid lighten($gray-400, 10);
    }
  }

  .dropdown-item {
    padding: 0;
    font-weight: initial;

    .funding-preview,
    .funding-card-preview {
      border-radius: 0;
      border: none;
      background: transparent;
      color: inherit !important;
    }

    &.active .funding-details,
    &:hover .funding-details {
      color: inherit;
    }
  }

  .badge {
    transition: none;
    background: rgba(48, 144, 241, 0.1);
    color: #3090f1;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.1px;
    padding: 4px;
    border-radius: 4px;
    text-transform: uppercase;
  }
}
.footer {
  color: $gray-700;
}
</style>
