import axios, { AxiosResponse } from "@/lib/axios";
import {
  CreateFundingCard,
  CreatePlaidBankAccount,
  FundingCardType,
  FundingType,
  V2FundingSource,
} from "@/types/Funding";
import { RootState, storeBuilder } from "./storeBuilder";
import { ActionHandler, MutationHandler } from "./vuex-typex";

type FundingStoreState = {
  sources: V2FundingSource[];
};

type FundingMutation<Payload = void> = MutationHandler<
  FundingStoreState,
  Payload
>;
type FundingAction<Payload = void, Type = void> = ActionHandler<
  FundingStoreState,
  RootState,
  any,
  Payload,
  Type
>;

const builder = storeBuilder.module<FundingStoreState>("funding", {
  sources: [],
});

const BASE_PATH = "/api/v2/funding";

// #region Getters

const getAll = builder.read((state) => state.sources, "all");

const getDefaultSource = builder.read((state) => {
  return state.sources.find((source) => source.default);
}, "default");

const getSubscriptionCards = builder.read((state) => {
  return state.sources.filter(
    (source) =>
      source.type === FundingType.CARD &&
      source.fundingType === FundingCardType.SUBSCRIPTION
  );
}, "subscriptionCards");

export const getters = {
  get default() {
    return getDefaultSource();
  },
  get all() {
    return getAll();
  },
  get subscriptionCards() {
    return getSubscriptionCards();
  },
};

// #endregion

// #region Mutations

const setFundingSources: FundingMutation<V2FundingSource[]> = (
  state,
  sources
) => {
  state.sources = sources;
};

const addFundingSource: FundingMutation<V2FundingSource> = (state, source) => {
  state.sources = [...state.sources, source];
};

export const mutations = {
  setFundingSources: builder.commit(setFundingSources),
  addFundingSource: builder.commit(addFundingSource),
};

// #endregion

// #region Actions

type FundingListResponse = {
  total: number;
  data: V2FundingSource[];
};
const fetchFundingSources: FundingAction<
  void,
  V2FundingSource[]
> = async () => {
  const { data }: AxiosResponse<FundingListResponse> =
    await axios.get(BASE_PATH);

  mutations.setFundingSources(data.data);

  return data.data;
};

const createFundingCard: FundingAction<
  CreateFundingCard,
  V2FundingSource
> = async (_context: any, data: CreateFundingCard) => {
  const response: AxiosResponse<V2FundingSource> = await axios.post(
    BASE_PATH + "/cards",
    data
  );

  mutations.addFundingSource(response.data);

  return response.data;
};

const createPlaidBankAccount: FundingAction<
  CreatePlaidBankAccount,
  AxiosResponse<V2FundingSource>
> = async (_context: any, data: CreatePlaidBankAccount) => {
  const response: AxiosResponse<V2FundingSource> = await axios.post(
    BASE_PATH + "/bank/plaid",
    data
  );

  mutations.addFundingSource(response.data);

  return response;
};

export const actions = {
  fetchFundingSources: builder.dispatch(fetchFundingSources),
  createFundingCard: builder.dispatch(createFundingCard),
  createPlaidBankAccount: builder.dispatch(createPlaidBankAccount),
};

// #endregion
