/* eslint-disable @typescript-eslint/no-empty-function */
import noop from "lodash/noop";
import isEmail from "validator/lib/isEmail";
import { CardTime, CardStyle, Card, CardType } from "./types/Card";
import { Subscription } from "./types/Subscription";

declare global {
  interface Window {
    mozRTCPeerConnection: new (
      connection: RTCConfiguration
    ) => RTCPeerConnection;
    webkitRTCPeerConnection: new () => RTCPeerConnection;
  }
}

export function formatNumOnly(rawStr: string) {
  return rawStr.replace(/\D/g, "");
}

export function formatSpendLimit(rawCurrency: string) {
  const currency = formatNumOnly(rawCurrency);
  return "$" + currency;
}

// this doesn't realllyyyy work correctly, currently replaces
// anything that isn't a number or a '.' but allows
// multiple '.'s and the '.'s can have more than 2 digits
// following them
export function formatCurrency(rawCurrency: string) {
  return rawCurrency.replace(/[^0-9.]/, "");
}

export function formatPhone(rawPhone: string) {
  const numPhone = formatNumOnly(rawPhone);
  let phone = "";
  if (numPhone.slice(0, 3)) {
    phone += `(${numPhone.slice(0, 3)}`;
  }
  if (numPhone.slice(3, 6)) {
    phone += `) ${numPhone.slice(3, 6)}`;
  }
  if (numPhone.slice(6, 10)) {
    phone += `-${numPhone.slice(6, 10)}`;
  }
  return phone;
}

export function formatSSN(rawSSN: string) {
  const numSSN = formatNumOnly(rawSSN);
  let ssn = "";
  if (numSSN.slice(0, 3)) {
    ssn += numSSN.slice(0, 3);
  }
  if (numSSN.slice(3, 5)) {
    ssn += `-${numSSN.slice(3, 5)}`;
  }
  if (numSSN.slice(5, 9)) {
    ssn += `-${numSSN.slice(5, 9)}`;
  }
  return ssn;
}

export function formatMicroauth(rawAuth: string) {
  const numAuth =
    (rawAuth.charAt(0) === "." ? "0" : "") + formatNumOnly(rawAuth);
  let auth = "";
  if (numAuth.slice(0, 1)) {
    auth += `$${numAuth.slice(0, 1)}`;
  }
  if (numAuth.slice(1, 3)) {
    auth += `.${numAuth.slice(1, 3)}`;
  }
  return auth;
}

export function formatTimePeriod(period: string) {
  switch (period) {
    case CardTime.ANNUALLY:
      return "per year";
    case CardTime.MONTHLY:
      return "per month";
    case CardTime.WEEKLY:
      return "per week";
    case CardTime.TRANSACTION:
      return "per transaction";
    case CardTime.FOREVER:
      return "total";
    default:
      return "";
  }
}

export function formatTimePeriodAbbr(period: string) {
  switch (period) {
    case CardTime.ANNUALLY:
      return "yr.";
    case CardTime.MONTHLY:
      return "mo.";
    case CardTime.WEEKLY:
      return "wk.";
    case CardTime.TRANSACTION:
      return "tx.";
    case CardTime.FOREVER:
      return "total";
    default:
      return "";
  }
}

export function formatAddress(
  address1: string,
  address2 = "",
  zipcode: string,
  city = "",
  state = ""
) {
  const address = address1 + (address2 && ` ${address2}`);
  const citystatezip = (city && state && `${city} ${state} `) + `${zipcode}`;
  return `${address}, ${citystatezip}`;
}

export function validateEmail(email: string) {
  return isEmail(String(email).toLowerCase());
}

export function loadScriptAsync(
  id: string,
  src: string,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const scriptTag = document.createElement("script");
  scriptTag.setAttribute("type", "text/javascript");
  scriptTag.setAttribute("src", src);
  scriptTag.setAttribute("id", id);
  scriptTag.setAttribute("defer", "defer");
  scriptTag.setAttribute("async", "async");
  scriptTag.onload = successCallback;
  scriptTag.onerror = errorCallback;
  document.body.appendChild(scriptTag);
}

export function linkStylesheetAsync(stylesheet: string) {
  const linkTag = document.createElement("link");
  linkTag.setAttribute("href", stylesheet);
  linkTag.setAttribute("rel", "stylesheet");
  document.body.appendChild(linkTag);
}

export function validateUrl(url: string) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(url);
}

export function parseDeclineReason(reasonCode: string) {
  switch (reasonCode) {
    case "CARD_PAUSED":
      return "Card was paused at time of transaction.";
    case "CARD_CLOSED":
      return "Card was closed at time of transaction.";
    case "GLOBAL_TRANSACTION_LIMIT":
      return "Transaction exceeded your global spend limit.";
    case "GLOBAL_MONTHLY_LIMIT":
      return "Transaction exceeded your monthly spend limit.";
    case "USER_TRANSACTION_LIMIT":
      return "Transaction exceeded your card's spend limit.";
    case "UNAUTHORIZED_MERCHANT":
      return "An unauthorized merchant charged your card.";
    case "SINGLE_USE_RECHARGED":
      return "A single-use card was charged multiple times.";
    case "BANK_CONNECTION_ERROR":
      return "We were unable to connect to your funding source.";
    case "INSUFFICIENT_FUNDS":
      return "Your funding source didn't appear to have enough funds for this transaction.";
    case "MERCHANT_BLACKLIST":
      return "This merchant is not permitted to make charges on Privacy cards.";
    case "INVALID_CARD_DETAILS":
      return "Incorrect card details. The card form may have been filled out incorrectly.";
    case "BANK_NOT_VERIFIED":
      return "Your funding source had not yet been verified.";
    case "ACCOUNT_STATE_REVIEW":
    case "INACTIVE_ACCOUNT":
    case "ACCOUNT_STATE_TRANSACTION_FAIL":
    case "FOREIGN_MERCHANT_VELOCITY":
      return "Your account was paused at the time of this transaction.";
    case "MERCHANT_ABUSE":
      return "Suspicious merchant activity";
    default:
      break;
  }
}

export function getLogoSrc(
  style: CardStyle = { filename: "", lastModified: "" },
  type = "MERCHANT_LOCKED"
) {
  if (style.filename && type === "MERCHANT_LOCKED") {
    return (
      "https://s3.amazonaws.com/privacy-web/images/" +
      "cards/" +
      style.filename +
      "?" +
      "lastModified=" +
      (style.lastModified || "")
    );
  }
  return "";
}

export function getIsDarkBackground(bgColor: string | null | undefined) {
  const customBgColor = bgColor || "FFFFFF";

  const r = parseInt(customBgColor.substring(0, 2), 16);
  const g = parseInt(customBgColor.substring(2, 4), 16);
  const b = parseInt(customBgColor.substring(4, 6), 16);

  // Use the formula for luminance to determine the best color for readability
  return r * 0.299 + g * 0.587 + b * 0.114 < 186;
}

export function parseCentAmount(
  amount: any = 0,
  numDecimalPlaces?: number,
  currency?: string
) {
  if (amount === null || amount === undefined) {
    return amount;
  }
  if (typeof amount === "number") {
    return parseDollarAmount(amount / 100, numDecimalPlaces, currency);
  }
  amount = cleanCurrencyString(amount, currency);
  return parseDollarAmount(
    Number.parseInt(amount) / 100,
    numDecimalPlaces,
    currency
  );
}

export function parseDollarAmount(
  amount: any = 0,
  numDecimalPlaces?: number,
  currency?: string
) {
  if (amount === null || amount === undefined) {
    return amount;
  }

  // Assume there are 2 decimal places (USD and most other currencies) unless otherwise specified
  numDecimalPlaces =
    numDecimalPlaces || numDecimalPlaces === 0 ? numDecimalPlaces : 2;
  currency = currency || "$";

  if (typeof amount === "string") {
    amount = cleanCurrencyString(amount, currency);
  }

  // Now use JS locales API to add thousands separator and decimal formatting
  amount = parseFloat(amount).toLocaleString("en-US", {
    maximumFractionDigits: numDecimalPlaces,
    minimumFractionDigits: numDecimalPlaces,
  });

  if (amount.charAt(0) === "-") {
    amount = "-" + currency + amount.substr(1);
  } else {
    amount = currency + amount;
  }
  return amount;
}

interface Attributes {
  src: string;
  id: string;
  style: string;
}

function cleanCurrencyString(amount: any, currency = "$") {
  // Sometimes negative amounts come from the backend as (50.00)
  if (/[()]/g.test(amount)) {
    amount = "-" + amount.replace(/[()]/g, "");
  }

  if (amount.includes(",")) {
    amount = amount.replaceAll(",", "");
  }

  if (amount.includes(currency)) {
    amount = amount.replaceAll(currency, "");
  }
  return amount;
}

export function measureLatency(
  callback: (err: string | null, rtl?: number) => any
) {
  const attrs: Attributes = {
    src: "/net/p",
    id: "#perfMonitor",
    style: "display: none; width: 0; height: 0",
  };
  const ele: HTMLIFrameElement = document.createElement("iframe");

  for (const key in attrs) {
    ele.setAttribute(key, attrs[key as keyof Attributes]);
  }

  ele.addEventListener("load", () => {
    if (
      ele.contentWindow &&
      ele.contentWindow.performance &&
      ele.contentWindow.performance.timing
    ) {
      const perfTiming = ele.contentWindow.performance.timing;
      callback(null, perfTiming.responseStart - perfTiming.requestStart);
    } else {
      // Not all browsers have this API..
      callback("unsupported");
    }
    ele.remove();
  });

  document.body.append(ele);
}

// Pulled from: https://gist.github.com/Daniel-Hug/7273430.
export const math = {
  sum: function (array: number[]) {
    let num = 0;
    for (let i = 0, l = array.length; i < l; i++) num += array[i];
    return num;
  },
  mean: function (array: number[]) {
    return math.sum(array) / array.length;
  },
  variance: function (array: number[]) {
    const mean = math.mean(array);
    return math.mean(
      array.map(function (num: number) {
        return Math.pow(num - mean, 2);
      })
    );
  },
  standardDeviation: function (array: number[]) {
    return Math.sqrt(math.variance(array));
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pack(object: any) {
  // If this is an object or some other complex datatype, JSON serialize it so we can work with a string
  if (typeof object !== "string") {
    object = JSON.stringify(object);
  }

  // Copied and pasted to server/lib/util.js on the receiving end.
  const _bitwiseNegate = function (str: string) {
    return str.split("").reduce(function (prev, current) {
      // eslint-disable-next-line no-bitwise
      return prev + String.fromCharCode(~current.charCodeAt(0));
    }, "");
  };
  return _bitwiseNegate(btoa(object));
}

export function ice(callback: (candidates: string[]) => void) {
  callback = callback || noop;

  const iceCandidates: string[] = [];
  const RTCPeerConnection =
    window.mozRTCPeerConnection || window.webkitRTCPeerConnection;

  const configuration = {
    // Trying to be madd funky with these string urls. For the skript kiddies
    // servers in plaintext are:
    //    stun:stun.l.google.com:19302
    //    stun:stun1.l.google.com:19302
    //    stun:stun2.l.google.com:19302
    iceServers: [
      { urls: atob("c3R1bjpzdHVu") + atob("LmwuZ29vZ2xlLmNvbToxOTMwMg") },
      { urls: atob("c3R1bjpzdHVu") + "1" + atob("LmwuZ29vZ2xlLmNvbToxOTMwMg") },
      { urls: atob("c3R1bjpzdHVu") + "2" + atob("LmwuZ29vZ2xlLmNvbToxOTMwMg") },
    ],
  };

  try {
    if (!window.RTCPeerConnection) throw new Error("Unsupported");

    const pc = new RTCPeerConnection(configuration);
    pc.createDataChannel("dataChannel", {});

    pc.onicecandidate = (e) => {
      if (!e.candidate || !e.candidate.candidate) {
        return callback(iceCandidates);
      }
      iceCandidates.push(e.candidate.candidate);
    };

    pc.createOffer().then(
      (offer) => {
        pc.setLocalDescription(offer);
      },
      () => {
        callback([]);
      }
    );
  } catch (e) {
    callback([]);
  }
}

export function isValidSharedSpendLimitAmount(
  card: Card,
  subscription: Subscription
) {
  const spendLimit = Number(card.spendLimit);

  if (
    !spendLimit ||
    spendLimit > subscription?.features.cardSharingSpendLimit
  ) {
    return false;
  }

  return true;
}

export function isValidSharedSpendLimit(
  card: Card,
  subscription: Subscription
) {
  if (!isValidSharedSpendLimitAmount(card, subscription)) {
    return false;
  }

  if (card.spendLimitDuration === CardTime.TRANSACTION) {
    return false;
  }

  if (card.type === CardType.DIGITAL_WALLET) {
    return false;
  }

  return true;
}

export const INITIAL_TXN_LIMIT = 20;
