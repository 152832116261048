<template>
  <SimplePage
    :fullpage="false"
    class="downgrade-warning-container d-flex flex-column mt-5 justify-content-start"
  >
    <BaseButton variant="text" @click="back" class="mt-2 mb-3" size="sm">
      <SVGIcon icon="arrow-left" /> All Options
    </BaseButton>
    <div class="downgrade-warning-card d-flex flex-column mx-auto p-4">
      <div style="border-bottom: 1px solid #f0f0f5" class="text-center">
        <SVGIcon class="pb-3 error-icon" icon="error" size="16" />
        <p>
          An important note about downgrading to a {{ selectedPlan.name }} plan.
        </p>
      </div>

      <div style="border-bottom: 1px solid #f0f0f5">
        <p class="smaller">You will lose access to the following features</p>
        <ul>
          <li v-if="userPlanHasCategoryCards && !planHasCategoryCards">
            <h4 class="heading">Category Cards</h4>
          </li>
          <li
            v-if="userPlanHasDigitalWalletCards && !planHasDigitalWalletCards"
          >
            <h4 class="heading">Digital Wallet Cards</h4>
          </li>
        </ul>
        <p class="smaller">
          Once your plan ends, all {{ downgradeSummaryText }} cards in your
          account will become disabled.
        </p>
      </div>
      <div class="text-center">
        <span class="d-block font-weight-bold mb-4">
          Are you sure you want to downgrade?
        </span>
        <BaseButton
          variant="danger"
          class="select-plan-button"
          @click="submit"
          block
          size="md"
        >
          Yes, downgrade to {{ selectedPlan.name }}
        </BaseButton>
        <BaseButton
          variant="light"
          class="select-plan-button"
          @click="deny"
          block
          size="md"
        >
          No, keep my current plan
        </BaseButton>
      </div>
    </div>
    <BaseButton
      v-if="true"
      variant="text"
      @click="cancel"
      block
      class="mt-2"
      size="sm"
    >
      Cancel
    </BaseButton>
  </SimplePage>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { Confirm } from "@/mixins/Confirm";
import SVGIcon from "@/components/SVGIcon.vue";
import { cardStore, subscriptionStore } from "@/store";
import {
  Plan,
  SubscriptionState,
  SubscriptionType,
} from "@/types/Subscription";
import { formatDate, utcDate } from "@/lib/dates";
import { Toast } from "@/mixins/Toast";
import { OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    SVGIcon,
  },
})
export default class DowngradeWarning extends Mixins(Confirm, Toast) {
  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  get selectedPlan(): Plan {
    const selectedUuid = this.$cookies.get("planType");
    if (!selectedUuid) {
      return subscriptionStore.getters.freePlan;
    }
    return subscriptionStore.getters.plan(selectedUuid);
  }

  get planHasCategoryCards() {
    return !!this.selectedPlan.cardTypes?.categoryCards;
  }
  get planHasDigitalWalletCards() {
    return !!this.selectedPlan.cardTypes?.digitalWalletCards;
  }

  get userPlanHasCategoryCards() {
    return !!subscriptionStore.getters.subscription?.features?.categoryCards;
  }

  get userPlanHasDigitalWalletCards() {
    return !!subscriptionStore.getters.subscription?.features
      ?.digitalWalletCards;
  }

  get downgradeSummaryText() {
    let text = "";
    if (this.userPlanHasCategoryCards && !this.planHasCategoryCards) {
      text += "category-locked";
    }
    if (this.userPlanHasDigitalWalletCards && !this.planHasDigitalWalletCards) {
      text += text ? " and " : "";
      text += "digital wallet";
    }

    return text;
  }

  async created() {
    try {
      await cardStore.actions.getCards({});
    } catch (e) {
      /* noop */
    }
  }

  deny() {
    this.$piwik.trackClick({ name: "Downgrade Deny CTA" });
    this.$router.push({ name: "subscription-plan" });
  }

  cancel() {
    this.$piwik.trackClick({ name: "Downgrade Cancel CTA" });
    this.$router.push({ name: "subscription-plan" });
  }

  async submit() {
    if (this.selectedPlan.type !== SubscriptionType.FREE) {
      this.$router.push({ name: `onboarding-${OnboardingSteps.BILLING}` });
      return;
    }
    let subscription;
    try {
      subscription = await subscriptionStore.actions.delete(
        this.subscription!.subscriptionUuid
      );
    } catch (err) {
      this.errorToast(
        "There was an error downgrading your plan. Please try again"
      );
      return;
    }

    let endDate = "";

    if (subscription.state === SubscriptionState.CANCELLED) {
      endDate += " It will remain active until ";

      endDate += subscription.endDate
        ? formatDate("MM/DD/YYYY", utcDate(subscription.endDate))
        : "the end of the current billing period.";
    }

    await this.confirm(`Your subscription has been cancelled.${endDate}`, {
      title: "Subscription Cancelled",
      okOnly: true,
    });
    this.$piwik.trackClick({ name: "Downgrade Accept CTA" });
    this.$router.push({ name: "account" });
  }

  back() {
    this.$piwik.trackClick({
      name: "Downgrade All options CTA",
    });
    this.$router.push({ name: "subscription-plan" });
  }
}
</script>

<style lang="scss" scoped>
.downgrade-warning-container {
  background: #f0f0f5;
  min-height: calc(100vh);
  padding-top: 48px;
}

.option-link {
  font-size: 14px;
  color: $gray-800;
}

.heading {
  font-size: 14px;
  font-weight: 600;
}

.smaller {
  font-size: 12px;
}

ul {
  padding-inline-start: 24px;
}

.error-icon {
  color: $danger-default;
}

.downgrade-warning-card {
  max-width: 357px;
  background: white;
  border-radius: 16px;
  gap: 24px;
  box-shadow:
    0px 2px 8px 0px rgba(0, 0, 0, 0.08),
    0px 0px 0px 1px rgba(0, 0, 0, 0.08);
}
p {
  color: #323242;
}
</style>
