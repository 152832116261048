<template>
  <KybVerificationContainer>
    <BaseForm @submit="submit" class="pt-0 mt-0">
      <h3 class="heading">
        Please provide the following information about your business
      </h3>
      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        name="name"
        label="Legal Business Name"
        placeholder="Your business name"
        v-model="business.name"
        type="text"
        maxlength="255"
        :state="errors.name ? false : null"
      ></BaseInput>
      <BaseInput
        name="ein"
        label="Employer Identification Number (EIN)"
        placeholder="Your 9-digit EIN"
        v-model="business.ein"
        type="text"
        maxlength="9"
        :state="errors.ein ? false : null"
      ></BaseInput>
      <BaseInput
        name="dba"
        label="Doing Business As (DBA), if any"
        v-model="business.doingBusinessAs"
        type="text"
        maxlength="128"
        :state="errors.doingBusinessAs ? false : null"
      ></BaseInput>
      <BaseInput
        name="website"
        label="Company Website, if any"
        placeholder="https://"
        v-model="business.website"
        type="text"
        maxlength="128"
        :state="errors.website ? false : null"
      ></BaseInput>
      <span class="mb-4 mt-3">Company Address</span>
      <AddressInput
        @address-changed="handleAddressChanged"
        :existingAddress="business.address.street1"
        :isError="errors.address.street1"
        label="Street Address"
        placeholder="Your US street address"
      />
      <BaseInputGroup>
        <BaseInput
          name="street2"
          label="Suite"
          placeholder="#"
          v-model="business.address.street2"
          type="text"
          maxlength="128"
          :state="errors.address.street2 ? false : null"
        />
        <BaseInput
          name="zipcode"
          label="ZIP Code"
          placeholder="00000"
          v-model="business.address.zipcode"
          type="text"
          maxlength="5"
          :state="errors.address.zipcode ? false : null"
        />
      </BaseInputGroup>
      <p class="blurb text-left">
        This must be a physical address; we cannot accept a mail drop or P.O.
        box.
      </p>
      <div class="divider mb-4"></div>
      <BaseButton
        variant="primary"
        type="submit"
        :loading="waiting"
        class="submit-button"
        :disabled="!hasEnteredData"
      >
        Continue
      </BaseButton>
    </BaseForm>
  </KybVerificationContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { organizationStore, userStore } from "@/store";
import { Format } from "@/types/Form";
import { AddressResult } from "@/types/Onboarding";
import AddressInput from "@/components/AddressInput.vue";
import KybVerificationContainer from "./KybVerificationContainer.vue";

@Component({
  components: {
    AddressInput,
    KybVerificationContainer,
  },
})
export default class BusinessDetails extends Vue {
  get kybUser() {
    return userStore.getters.currentUser!.kybVerificationUser;
  }

  get piwikPrefix() {
    return this.kybUser ? "Verification" : "Onboarding";
  }

  nextStep() {
    this.$router.push({ name: "kybEINUpload" });
  }

  async created() {
    await userStore.actions.getCurrentUser();
    this.$piwik.trackEvent("Modal", "View", {
      name: `${this.piwikPrefix} Business Details`,
    });

    const currentStep = "kybBusinessDetails";
    if (this.kybUser) {
      userStore.actions.updateVerificationStep(currentStep);
    } else {
      userStore.actions.updateUser({ kybCurrentStep: currentStep });
    }
  }

  Format = Format;
  waiting = false;
  business = {
    name: "",
    ein: "",
    doingBusinessAs: "",
    website: "",
    address: {
      street1: "",
      street2: "",
      zipcode: "",
    },
  };
  errors = {
    name: false,
    doingBusinessAs: false,
    website: false,
    ein: false,
    address: {
      street1: false,
      street2: false,
      zipcode: false,
    },
  };
  errorMessage = "";

  get hasEnteredData(): boolean {
    return !!(
      this.business.name &&
      this.business.ein &&
      this.business.address.street1 &&
      this.business.address.zipcode
    );
  }

  handleAddressChanged(addressResult: AddressResult): void {
    // Fill in data from google.  If user needs to change it, they can.
    this.business.address.street1 = addressResult.address1;
    if (addressResult.zipcode) {
      this.business.address.zipcode = addressResult.zipcode;
    }
  }

  submit(): void {
    if (this.business.name.length > 0) {
      this.errors.name = false;
    } else {
      this.errors.name = true;
      this.errorMessage = "Please provide your organization's name";
      return;
    }

    if (this.business.ein.length === 9) {
      this.errors.ein = false;
    } else {
      this.errors.ein = true;
      this.errorMessage = "Please provide your organization's 9-digit EIN";
      return;
    }

    if (this.business.address.street1.length > 0) {
      this.errors.address.street1 = false;
    } else {
      this.errors.address.street1 = true;
      this.$piwik.trackContentImpression(
        `${this.piwikPrefix} Business Details`,
        "Address error"
      );
      this.errorMessage = "Please provide your organization's address";
      return;
    }

    if (this.business.address.zipcode.length === 5) {
      this.errors.address.zipcode = false;
    } else {
      this.errors.address.zipcode = true;
      this.$piwik.trackContentImpression(
        `${this.piwikPrefix} Business Details`,
        "Address error"
      );
      this.errorMessage = "Please provide your organization's ZIP code";
      return;
    }

    this.$piwik.trackClick({
      name: `${this.piwikPrefix} Business Details CTA`,
    });

    this.waiting = true;
    organizationStore.actions
      .details(this.business)
      .then(() => {
        this.nextStep();
      })
      .catch((err) => {
        this.errorMessage = err.response?.data?.message || "There was an error";
      })
      .finally(() => {
        this.waiting = false;
      });
  }
}
</script>
