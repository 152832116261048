<template>
  <KybVerificationContainer>
    <BaseForm @submit="submit" class="pt-0 mt-0">
      <h3 class="heading">
        Please upload a copy of your business's EIN Assignment Letter from the
        IRS
      </h3>
      <p class="blurb">
        This document, also known as a Form 147C, is necessary for us to confirm
        your business's legitimacy to conduct various financial activities.
      </p>

      <b-alert variant="danger" :show="errorMessage.length > 0">
        {{ errorMessage }}
      </b-alert>
      <div
        v-if="!isFileAttached"
        class="upload-area mb-3"
        @click="openFileSelect"
      >
        <input
          type="file"
          ref="fileInput"
          class="file-input"
          accept=".pdf,.jpeg,.jpg,.png"
          @change="handleFileSelect"
        />
        <div class="upload-content">
          <SVGIcon icon="upload" size="16" class="upload-icon mr-2" />
          <div class="upload-text">Upload Document</div>
        </div>
      </div>
      <div v-else class="selected-file mb-3">
        <div class="file-info">
          <span class="file-name">{{ fileName }}</span>
          <button class="remove-button" @click="removeFile">
            <SVGIcon icon="cross" />
          </button>
        </div>
        <div class="success-message mt-3">File successfully attached.</div>
      </div>
      <div class="supported-files mb-4" v-if="!fileName">
        Files supported: PDF, JPEG, PNG
      </div>

      <div class="divider mb-3" />
      <BaseButton
        class="mt-2"
        variant="primary"
        type="submit"
        :disabled="!fileName"
        :loading="waiting"
        >Submit & Continue</BaseButton
      >
    </BaseForm>
  </KybVerificationContainer>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { BAlert } from "bootstrap-vue";
import SVGIcon from "@/components/SVGIcon.vue";
import { organizationStore, userStore } from "@/store";
import KybVerificationContainer from "@/views/kyb-verification/KybVerificationContainer.vue";

@Component({
  components: {
    BAlert,
    SVGIcon,
    KybVerificationContainer,
  },
})
export default class EINUpload extends Vue {
  get kybUser() {
    return userStore.getters.currentUser!.kybVerificationUser;
  }

  get piwikPrefix() {
    return this.kybUser ? "Verification" : "Onboarding";
  }

  nextStep() {
    this.$router.push({ name: "kybConfirmBusinessOnlyOwner" });
  }

  async created() {
    await userStore.actions.getCurrentUser();
    this.$piwik.trackEvent("Modal", "View", {
      name: `${this.piwikPrefix} EIN Upload`,
    });

    const currentStep = "kybEINUpload";
    if (this.kybUser) {
      userStore.actions.updateVerificationStep(currentStep);
    } else {
      userStore.actions.updateUser({ kybCurrentStep: currentStep });
    }
  }

  @Prop({ default: false }) fullpage!: boolean;
  errorMessage = "";
  waiting = false;
  isFileAttached = false;
  selectedFile: File | null = null;
  fileName = "";
  MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

  openFileSelect() {
    const fileInput = this.$refs.fileInput as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  handleFileSelect(event: any): void {
    this.errorMessage = "";

    const file = event.target.files[0];
    if (!file) {
      this.errorMessage = "Please select a file";
      return;
    }

    if (file.size > this.MAX_FILE_SIZE) {
      event.target.value = "";
      this.selectedFile = null;
      this.fileName = "";
      this.isFileAttached = false;
      this.errorMessage = "File is too large. Maximum size is 10MB.";
      return;
    }

    this.selectedFile = file;
    this.fileName = file.name;
    this.isFileAttached = true;
  }

  removeFile(): void {
    this.selectedFile = null;
    this.fileName = "";
    this.isFileAttached = false;
    const fileInput = this.$refs.fileInput as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  }

  submit() {
    this.$piwik.trackClick({
      name: `${this.piwikPrefix} EIN Upload CTA`,
    });

    this.waiting = true;
    organizationStore.actions
      .uploadEinDocument({ file: this.selectedFile! })
      .then(() => {
        this.waiting = false;
        this.nextStep();
      })
      .catch((err) => {
        this.errorMessage =
          err.response?.data?.message || "There was an error uploading file";
        this.waiting = false;
      });
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 320px;
}

.upload-area {
  border: 1px dashed #007bff;
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  cursor: pointer;
  background: #3090f11a;
}

.file-input {
  display: none;
}

.upload-content {
  display: flex;
  justify-content: center;
}

.upload-text {
  font-weight: 600;
}

.file-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  font-weight: 600;
  background-color: #1f995c1a;
  border-radius: 8px;
}

.remove-button {
  background: none;
  border: none;
}

.success-message {
  color: #28a745;
}
</style>
