<template>
  <div class="modal-card-edit-spend-limit">
    <BaseForm class="modal-card-edit-spend-limit-form" @submit="onClickSave">
      <div class="header"><div class="title">Spend Limit</div></div>
      <b-alert
        :show="isSharedCard && !isValidSpendLimitAmount"
        variant="info"
        class="shared-card-info"
      >
        The spend limit for Shared Cards cannot exceed
        {{ sharedSpendLimitAmount }}.
      </b-alert>
      <b-alert
        :show="isSharedCard && !isValidSpendLimitDuration"
        variant="info"
        class="shared-card-info"
      >
        Shared Cards cannot have a {{ formattedDuration }} spend limit.
      </b-alert>
      <div class="spend-limit-container">
        <b-input
          ref="spend-limit"
          class="spend-limit -mobile"
          number
          :value="`$${viewSpendLimit}`"
          id="modal-card-edit-spend-limit-input"
          maxlength="7"
          autofill="false"
          autofocus
          @input="checkNewLimit"
          :formatter="formatSpendLimit"
        />
        <b-input
          ref="spend-limit"
          class="spend-limit"
          v-if="!isUnlimited"
          number
          :value="`$${viewSpendLimit}`"
          id="modal-card-edit-spend-limit-input"
          maxlength="7"
          autofill="false"
          autofocus
          @input="checkNewLimit"
          :formatter="formatSpendLimit"
          data-test="spend-limit-input"
        />
        <div class="spend-limit -unlimited" v-else>No Limit</div>
        <div class="slider-range">
          <div class="range-label">$1</div>
          <div class="outer-slider" v-if="!hideLimitSlider">
            <input
              class="slider"
              type="range"
              :min="0"
              :max="sliderMax"
              :step="5"
              v-model="viewSpendLimit"
              @input="checkNewLimit"
            />
          </div>
          <div class="range-label" v-if="isLimitRequired">${{ sliderMax }}</div>
          <div class="range-label -infinite" v-else>∞</div>
        </div>
      </div>
      <div class="duration-select">
        <div
          class="button-grid"
          :class="{ '-unused': showSingleUse, '-shared': isSharedCard }"
        >
          <button
            class="duration-button"
            type="button"
            :class="{
              '-active': viewSpendLimitDuration === time && !viewBurner,
              '-disabled': isUnlimited,
            }"
            v-for="time in allowedDurations"
            :key="time"
            @click="onChangeViewSpendDuration(time)"
          >
            {{
              time === CardTime.MONTHLY
                ? "Per Month"
                : time === CardTime.ANNUALLY
                  ? "Per Year"
                  : time === CardTime.TRANSACTION
                    ? "Per Transaction"
                    : "Total"
            }}
          </button>
          <button
            v-if="showSingleUse"
            type="button"
            class="duration-button"
            :class="{ '-active': viewBurner, '-disabled': isUnlimited }"
            @click="onChangeViewBurner"
          >
            Single-Use <span>Closes shortly after first use</span>
          </button>
        </div>
        <BaseButton
          class="pill-button"
          type="submit"
          variant="primary"
          shape="round"
          :loading="submitting"
          :disabled="!isValidSpendLimit"
        >
          {{
            isUnlimited
              ? "Set No Limit"
              : "Set $" + viewSpendLimit + " Spend Limit"
          }}
        </BaseButton>
        <BaseButton
          class="pill-button"
          variant="light"
          shape="round"
          :loading="submitting"
          @click="onCancel"
        >
          Cancel
        </BaseButton>
      </div>
    </BaseForm>
  </div>
</template>
<script lang="ts">
import { BAlert } from "bootstrap-vue";
import { Component, Prop, Mixins } from "vue-property-decorator";
import numbro from "numbro";
import { User } from "@/types/User";
import { Card, CardType, CardTime } from "@/types/Card";
import { EVENTS } from "@/types/Event";
import { cardStore, eventStore, userStore } from "@/store";
import { Toast } from "@/mixins/Toast";
import { Subscription } from "@/types/Subscription";
import {
  formatSpendLimit,
  isValidSharedSpendLimitAmount,
  isValidSharedSpendLimit,
  formatTimePeriod,
} from "../util";

@Component({
  components: {
    BAlert,
  },
})
export default class EditSpendLimitForm extends Mixins(Toast) {
  @Prop() card!: Card;
  @Prop({ default: false }) hideLimitSlider?: boolean;
  @Prop() isSampleCard?: boolean;
  @Prop({ default: false }) isSharingCard?: boolean;
  @Prop() subscription!: Subscription;

  CardTime = CardTime;
  CardType = CardType;
  formatSpendLimit = formatSpendLimit;

  submitting = false;
  ready = false;
  viewSpendLimit: number | string = 100;
  viewSpendLimitDuration = CardTime.MONTHLY;
  viewBurner = false;

  get user(): User | null {
    return userStore.getters.currentUser;
  }

  get isNewCard(): boolean {
    return !this.card.created;
  }

  get showSingleUse(): boolean {
    return (
      this.card.unused &&
      this.card.type !== CardType.DIGITAL_WALLET &&
      !this.isCategoryCard
    );
  }

  get isSharedCard(): boolean {
    return !!this.card.sharedWithRecipients?.length || !!this.isSharingCard;
  }

  get isValidSpendLimitAmount(): boolean {
    if (this.isSharedCard) {
      return isValidSharedSpendLimitAmount(
        {
          ...this.card,
          spendLimit: this.viewSpendLimit,
          spendLimitDuration: this.viewSpendLimitDuration,
        },
        this.subscription
      );
    }
    return true;
  }

  get isCategoryCard(): boolean {
    return (
      this.card?.type === CardType.UNLOCKED && !!this.card.merchantCategory
    );
  }

  get allowedDurations(): CardTime[] {
    if (this.isSharedCard) {
      return [CardTime.MONTHLY, CardTime.ANNUALLY, CardTime.FOREVER];
    }

    return [
      CardTime.MONTHLY,
      CardTime.ANNUALLY,
      CardTime.TRANSACTION,
      CardTime.FOREVER,
    ];
  }

  get formattedDuration(): string {
    return formatTimePeriod(this.card?.spendLimitDuration);
  }

  get isValidSpendLimitDuration(): boolean {
    return (
      this.allowedDurations.includes(this.card?.spendLimitDuration) ||
      this.allowedDurations.includes(this.viewSpendLimitDuration)
    );
  }

  get isLimitRequired(): boolean {
    return this.isSharedCard || this.viewBurner;
  }

  get sliderMax(): number {
    const monthlyLimit = parseInt(this.user?.monthlySpendLimit || "0");

    if (this.isSharedCard) {
      return this.subscription?.features.cardSharingSpendLimit || 0;
    }

    if (this.isLimitRequired) {
      return monthlyLimit;
    }

    return monthlyLimit + 5;
  }

  get isUnlimited(): boolean {
    return (
      !this.isLimitRequired && Number(this.viewSpendLimit) >= this.sliderMax
    );
  }

  get isValidSpendLimit(): boolean {
    if (this.isSharedCard) {
      return isValidSharedSpendLimit(
        {
          ...this.card,
          spendLimit: this.viewSpendLimit,
          spendLimitDuration: this.viewSpendLimitDuration,
        },
        this.subscription
      );
    }

    if (this.viewBurner) {
      const spendLimit = Number(this.viewSpendLimit);
      return !!spendLimit && spendLimit < this.sliderMax;
    }

    return true;
  }

  get sharedSpendLimitAmount(): string {
    return numbro(
      this.subscription?.features.cardSharingSpendLimit ?? 0
    ).formatCurrency("0,0");
  }

  mounted(): void {
    this.viewBurner = this.card.type === CardType.SINGLE_USE;
    this.viewSpendLimit = this.card.spendLimit
      ? Number(this.card.spendLimit)
      : this.sliderMax;
    this.viewSpendLimitDuration =
      this.card.spendLimitDuration || CardTime.MONTHLY;
  }

  onCancel(): void {
    this.submitting = false;
    this.$emit("cancel-update");
  }

  checkNewLimit(e: string | Event): void {
    if (!this.user) return;
    if (e instanceof Event) {
      const target = e.target as HTMLInputElement;
      this.viewSpendLimit = target.valueAsNumber;
    } else {
      this.viewSpendLimit = Number(e.replace("$", ""));
    }

    if (!this.viewSpendLimit || !Number(this.viewSpendLimit)) {
      this.viewSpendLimit = 1;
    }

    if (this.isLimitRequired && Number(this.viewSpendLimit) >= this.sliderMax) {
      this.viewSpendLimit = this.sliderMax;
    }
  }

  onChangeViewSpendDuration(duration: CardTime): void {
    if (this.isUnlimited) {
      return;
    }

    this.viewSpendLimitDuration = duration;
    this.viewBurner = false;
  }

  onChangeViewBurner(): void {
    if (this.isUnlimited) {
      return;
    }

    this.viewBurner = true;
    this.viewSpendLimitDuration = CardTime.FOREVER;
  }

  onClickSave(): void {
    const limit = !this.isUnlimited;

    if (
      (limit && !this.viewSpendLimit) ||
      this.submitting ||
      (this.viewBurner && Number(this.viewSpendLimit) < 1)
    ) {
      return;
    }

    if (this.viewBurner && !limit) {
      this.errorToast("You can't remove the limit on a Single-Use card");
      return;
    }

    this.submitting = true;

    const updateData: Partial<Card> = {
      spendLimit: limit ? Number(this.viewSpendLimit) : "",
      spendLimitDuration: this.viewSpendLimitDuration || CardTime.MONTHLY,
    };

    if (this.viewBurner) {
      updateData.type = CardType.SINGLE_USE;
    } else if (this.card.type === CardType.SINGLE_USE) {
      updateData.type = CardType.MERCHANT_LOCKED;
    }

    const updatedCard = { ...this.card, ...updateData };

    if (this.isNewCard || this.isSampleCard) {
      this.$emit("update-card", updatedCard);
      this.submitting = false;
      return;
    }

    cardStore.actions
      .update({ uuid: this.card.cardUuid, updates: updateData })
      .then(() => {
        eventStore.actions.record({
          name: EVENTS.CARD.SET_SPEND_LIMIT,
          data: {
            cardType: updateData.type,
            spendLimit: updateData.spendLimit,
            spendLimitDuration: updateData.spendLimitDuration,
          },
        });
        this.$emit("update-card", updatedCard);
      })
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message || "Couldn't update spend limit"
        );
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/mixins.scss";

@mixin input {
  padding: 15px;
  background: $gray-100;
  border: 0;
  border-radius: $border-radius;
  font-size: 20px;
}

.modal-card-edit-spend-limit-form {
  @include modal-card-edit;

  padding: 0;
  width: auto;
  max-width: 320px;
  margin: 0 auto;

  > .header {
    padding-top: 30px;
    padding-bottom: 24px;
    margin-bottom: 0;
  }

  > .shared-card-info {
    margin-bottom: 14px;
  }

  > .spend-limit-container {
    @include input;

    padding: 0;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media #{$media-phone} {
      width: 100%;
    }
  }

  > .spend-limit-container > .spend-limit {
    align-self: center;
    padding: 25px 15px 15px;
    width: 160px;
    background: transparent;
    border: 0;
    font-size: 20px;
    font-weight: 500;
    text-align: center;

    &.-mobile {
      display: none;
    }

    @media (max-width: 600px) {
      &.-mobile {
        display: block;
      }

      &:not(.-mobile) {
        display: none;
      }
    }

    @media #{$media-phone} {
      padding-top: 15px;
      font-size: 30px;
    }
  }

  > .spend-limit-container > .spend-limit.-unlimited {
    @media #{$media-phone} {
      display: none;
    }
  }

  > .spend-limit-container > .slider-range {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding-bottom: 15px;

    > .range-label {
      padding: 10px;
      font-weight: bold;
      font-size: 14px;
      color: $gray-600;

      &.-infinite {
        font-size: 18px;
      }
    }

    > .outer-slider {
      display: flex;
      justify-content: center;
      width: 90%;
    }

    @media #{$media-phone} {
      display: none;
    }
  }

  > .spend-limit-container .slider {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 2px;
    background: fade-out($gray-600, 0.5); /* Grey background */
    outline: none; /* Remove outline */

    &::-webkit-slider-thumb {
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      height: 26px;
      width: 26px;
      background: $white; /* Green background */
      border-radius: $border-radius;
      box-shadow:
        0 0 0 2px $blue,
        0 4px 6px 2px $shadow-black;
      cursor: -webkit-grab;
      cursor: grab;

      &:active {
        cursor: -webkit-grabbing;
        cursor: grabbing;
      }
    }
  }

  .duration-select {
    margin: 20px -1px 0;
    overflow-y: visible;
    transition-property: height, opacity;
    transition-duration: $duration-short;
    transition-timing-function: $ease-in-out-expo;

    &.-hidden {
      height: 0;
      opacity: 0;
      pointer-events: none;
    }
  }

  .duration-select > .button-grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
  }

  .duration-select > .button-grid > .duration-button {
    flex-basis: 120px;
    flex-grow: 2;
    padding: 10px 0;
    min-height: 50px;
    background-color: $gray-300;
    border: 1px solid $white;
    border-radius: initial;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition:
      box-shadow $duration-shorter,
      opacity $duration-short,
      background-color $duration-shorter;
    color: $gray-800;

    > span {
      margin-left: 15px;
      color: $gray-400;

      @media #{$media-phone} {
        display: block;
      }
    }

    &:nth-child(1) {
      border-top-left-radius: $border-radius;
    }

    &:nth-child(2) {
      border-top-right-radius: $border-radius;
    }

    &:nth-child(3) {
      border-bottom-left-radius: $border-radius;
    }

    &:nth-child(4) {
      border-bottom-right-radius: $border-radius;
    }

    &:nth-child(5) {
      border-radius: 0 0 $border-radius $border-radius;
    }

    &.-active {
      z-index: 1;
      background: mix($blue, $gray-300, 10%);
      box-shadow:
        0 0 0 2px $blue,
        inset 0 0 0 2px $white;
    }

    &.-disabled {
      opacity: 0.5;
      box-shadow: initial;
      background-color: $gray-300;
      cursor: initial;

      &.-active {
        box-shadow: none;
      }
    }
  }

  .duration-select > .button-grid.-unused > .duration-button {
    &:nth-child(3),
    &:nth-child(4) {
      border-radius: 0;
    }
  }

  .duration-select > .button-grid.-shared {
    flex-direction: column;

    .duration-button {
      flex-basis: auto;
      flex-grow: 0;
    }

    .duration-button:nth-child(1) {
      border-top-right-radius: $border-radius;
    }

    .duration-button:nth-child(2) {
      border-radius: 0;
    }

    .duration-button:nth-child(3) {
      border-bottom-right-radius: $border-radius;
    }

    &.-unused {
      .duration-button:nth-child(3) {
        border-radius: 0;
      }

      .duration-button:nth-child(4) {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }

  .duration-select > .pill-button {
    margin-top: 20px;
    width: 100%;

    & + .pill-button {
      margin-top: 8px;
    }
  }

  .bottom-content-wrapper {
    margin-bottom: -30px;
    height: 75px;
  }

  .bottom-content-wrapper > .limit-note {
    padding: 15px;
    text-align: center;

    @media #{$media-phone} {
      display: none;
    }
  }

  .bottom-content-wrapper > .limit-note > a {
    text-decoration: underline;
    color: $blue;
  }

  .bottom-content-wrapper > .pill-button {
    display: none;

    @media #{$media-phone} {
      display: initial;
    }
  }

  .bottom-content-wrapper > .pill-button > a {
    margin-top: 10px;
  }

  .bottom-content-wrapper > .pill-button > a {
    background-color: $gray-300;
  }

  .bottom-content-wrapper > .pill-button.-disabled > a {
    opacity: 0.5;
  }
}
</style>
