<template>
  <SimplePage :fullpage="fullpage" class="add-funding">
    <!-- Select: Fund by Card or Bank Account -->
    <BaseForm class="funding-type-select" v-show="source.type === ''">
      <h1>Connect Your Bank</h1>
      <p class="blurb">
        Connect using your online banking login details, or your routing &amp;
        account numbers.
      </p>
      <div
        class="selection funding-type"
        @click="selectOnlineBank"
        data-test="funding-type-online-bank"
      >
        <img
          src="/assets/images/onboarding/funding-connection-login.png"
          width="80"
          alt=""
        />
        Online Banking Login
        <p class="subtitle">Takes around 15 seconds</p>
      </div>
      <div
        class="selection funding-type"
        @click="source.type = 'routing'"
        data-test="funding-type-alternate"
      >
        <img
          src="/assets/images/onboarding/funding-connection-check.png"
          width="72"
          alt=""
        />
        Routing &amp; Account Number
        <p class="subtitle">Takes 1-2 business days</p>
      </div>
    </BaseForm>

    <!-- //- Routing / Account Number -->
    <BaseForm
      v-show="showAlternateFunding"
      class="signup-step bank-login"
      @submit="addBankAccountNumbers"
    >
      <h1>
        {{
          account.bankAccountID ? "Update Bank Connection" : "Connect Your Bank"
        }}
      </h1>
      <p class="blurb">
        Enter the account details of the bank account you'd like to add.
        <a v-b-modal.help-modal href="#"> Where are my numbers? </a>
      </p>
      <b-alert
        :show="submitted && errorMessage.length > 0"
        @click="submitted = false"
        variant="danger"
        data-test="alternate-funding-error"
      >
        {{ errorMessage }}
      </b-alert>
      <BaseInput
        label="Routing Number"
        @keyup="resetForm"
        name="routingNumber"
        placeholder="Routing/Transit Number"
        autocomplete="false"
        v-model="account.routingNumber"
        @blur="validateABA"
        data-test="alternate-funding-routing"
        maxlength="9"
      ></BaseInput>
      <BaseInput
        label="Account Number"
        @keyup="resetForm"
        name="accountNumber"
        placeholder="Account Number"
        autocomplete="false"
        v-model="account.accountNumber"
        data-test="alternate-funding-account"
        maxlength="17"
      >
      </BaseInput>
      <BaseButton
        variant="primary"
        type="submit"
        :disabled="loading"
        size="lg"
        data-test="alternate-funding-submit"
      >
        Submit
      </BaseButton>

      <a class="-standalone" @click="exitRoutingEntry" v-show="source.type"
        >Back</a
      >
    </BaseForm>

    <!-- Microdeposit Details -->
    <BaseForm
      :fullpage="true"
      class="signup-step micro-deposit-info"
      v-show="source.type === 'microDepositClarification'"
      data-test="microdeposit-info"
    >
      <h1 class="micro-deposit-estimate">
        Check your account
        {{ estimatedDepositArrival ? `${estimatedDepositArrival}!` : "" }}
      </h1>
      <p>
        Two deposits have been made to the account you provided and should
        appear on your statement in the next two business days.
      </p>
      <BaseButton
        variant="primary"
        @click="completeBankSetup"
        size="lg"
        data-test="add-funding-complete"
      >
        Back to My Account
      </BaseButton>
    </BaseForm>

    <!-- //- Select Financial Institution -->
    <BaseForm
      class="signup-step bank-select"
      :class="{ '-loading': loading }"
      v-show="showBankSelect"
    >
      <h1>Select Your Bank</h1>
      <p class="blurb">
        To fund purchases made with Privacy cards, you'll need to connect a bank
        account
      </p>
      <div class="plaid-connect-error" v-if="plaidErrorMessage">
        {{ plaidErrorMessage }}
      </div>

      <div
        v-if="!plaidErrorMessage"
        :class="['banking', { 'justify-content-around': !plaidLinkLoaded }]"
      >
        <div v-if="!plaidLinkLoaded" class="text-center">
          <b-spinner />
          <div>Loading Bank Connections</div>
        </div>

        <form v-if="plaidLinkLoaded">
          <div
            class="error-description"
            v-show="submitted && errorMessage.length > 0"
            @click="submitted = false"
          >
            {{ errorMessage }}
          </div>
          <input type="hidden" name="bank" :value="account.bank" />
          <div class="fields" v-show="account.bank === ''">
            <label class="search" :class="{ '-loading': bankSearchPending }">
              <input
                class="text search"
                v-model="bankSearchData.keyword"
                @keyup="searchBanks"
                placeholder="Search 15,000+ banks"
                data-test="input-bank-search"
              />
              <div
                class="clear-search"
                v-show="bankSearchData.keyword && !bankSearchPending"
                @click="resetBankSearch"
              ></div>
            </label>
            <label
              class="results"
              v-show="hasBankResults || bankSearchData.keyword"
            >
              <div
                class="bank"
                v-for="(bank, index) in bankSearchResults"
                :key="index"
                @click="selectBank(bank)"
              >
                <div class="img" v-show="bank.logo">
                  <img
                    :src="`data:image/png;base64,${bank.logo}`"
                    width="35"
                    alt=""
                  />
                </div>
                <div class="img" v-show="!bank.logo">
                  <img
                    :src="getBankIconFromInstID(bank.institution_id)"
                    width="20"
                    onerror="this.src='/assets/images/icons/bank-20.svg'"
                    alt=""
                  />
                </div>
                <span class="name">{{ bank.name }}</span>
              </div>
              <div
                class="no-results"
                v-show="!bankSearchPending && !hasBankResults"
              >
                <span class="name">Sorry, we couldn't find that bank.</span>
                <div
                  v-if="showAltFundingEmailCta"
                  class="alt-funding-email-cta"
                >
                  Not seeing your bank or looking for another way to connect
                  your funding source? Please write to&nbsp;<a
                    href="mailto:support@privacy.com"
                    >support@privacy.com</a
                  >.
                </div>
              </div>
            </label>
          </div>
        </form>

        <div
          class="popular-banks"
          v-show="!bankSearchData.keyword && plaidLinkLoaded"
        >
          <form class="select-bank" :class="{ '-loading': longTailOpening }">
            <div class="bank-container">
              <div
                class="bank js-add-chase"
                @click="selectBank({ institution_id: 'ins_56' })"
              >
                <img
                  src="/assets/images/onboarding/banks/chase.png"
                  width="100"
                  alt="Chase logo"
                />
              </div>
            </div>
            <div class="bank-container">
              <div
                class="bank js-add-bofa"
                @click="selectBank({ institution_id: 'ins_127989' })"
              >
                <img
                  src="/assets/images/onboarding/banks/bank-of-america.png"
                  width="90"
                  alt="Bank of America Logo"
                />
              </div>
            </div>
            <div class="bank-container">
              <div
                class="bank"
                @click="selectBank({ institution_id: 'ins_127991' })"
              >
                <img
                  src="/assets/images/onboarding/banks/wells-fargo.png"
                  width="50"
                  alt="Wells Fargo logo"
                />
              </div>
            </div>
            <div class="bank-container">
              <div
                class="bank"
                @click="selectBank({ institution_id: 'ins_5' })"
              >
                <img
                  src="/assets/images/onboarding/banks/citi.png"
                  width="50"
                  alt="Citi logo"
                />
              </div>
            </div>
            <div class="bank-container second-row">
              <div
                class="bank"
                @click="selectBank({ institution_id: 'ins_128026' })"
              >
                <img
                  src="/assets/images/onboarding/banks/capital-one.png"
                  width="90"
                  alt="Capital One logo"
                />
              </div>
            </div>
            <div class="bank-container second-row">
              <div
                class="bank"
                @click="selectBank({ institution_id: 'ins_127990' })"
              >
                <img
                  src="/assets/images/onboarding/banks/us-bank.png"
                  width="100"
                  alt="US Bank logo"
                />
              </div>
            </div>
            <div class="bank-container second-row">
              <div
                class="bank"
                @click="selectBank({ institution_id: 'ins_25' })"
              >
                <img
                  src="/assets/images/onboarding/banks/ally-bank.png"
                  width="60"
                  alt="Ally Bank logo"
                />
              </div>
            </div>
            <div class="bank-container second-row">
              <div
                class="bank"
                @click="selectBank({ institution_id: 'ins_14' })"
              >
                <img
                  src="/assets/images/onboarding/banks/td.png"
                  width="60"
                  alt="TD Bank logo"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </BaseForm>

    <p class="blurb" v-if="showManualBankConnectionLink">
      <a v-b-modal.manual-bank-connection-modal href="#"
        >Can't find your bank? Connect it manually.</a
      >
    </p>
    <div class="loading-text" v-if="loading">
      <b-spinner class="mx-auto" />
      <div>{{ loadingText }}</div>
    </div>

    <b-modal
      id="help-modal"
      centered
      static
      hide-footer
      hide-header
      class="text-center"
    >
      <h1>Routing &amp; Account Number</h1>
      <p>
        The numbers can be found on any check for the account you'd like to
        connect.
      </p>
      <p>
        <img
          src="/assets/images/onboarding/check-numbers.png"
          width="360"
          alt=""
        />
      </p>
      <b-button
        class="mt-5 w-50"
        @click="$bvModal.hide('help-modal')"
        variant="primary"
        >OK</b-button
      >
    </b-modal>
    <BaseModal
      id="manual-bank-connection-modal"
      centered
      static
      hide-footer
      hide-header
    >
      <div class="manual-bank-connection">
        <h1 class="my-4" v-show="showManualBankConnectionLink">
          Manual Bank Connection
        </h1>
        <h1 class="my-4" v-show="!showManualBankConnectionLink">
          We'll be in touch!
        </h1>
        <b-alert :show="hasAlternateFundingRequestError" variant="danger">
          {{ alternateFundingRequestErrorMessage }}
        </b-alert>
        <p>
          Before you can connect a bank account with account and routing
          numbers, we need to review your account.<br />
          When the review is done, we'll send you an email and you'll be able to
          connect your bank account.
        </p>
        <BaseButton
          type="submit"
          variant="primary"
          size="lg"
          v-show="showManualBankConnectionLink"
          @click="requestManualBankConnection"
        >
          Request Manual Bank Connection
        </BaseButton>
        <BaseButton
          type="submit"
          variant="primary"
          size="lg"
          v-show="!showManualBankConnectionLink"
          @click="closeManualBankConnectionModal"
        >
          Got it
        </BaseButton>
      </div>
    </BaseModal>
  </SimplePage>
</template>

<script lang="ts">
import { AxiosError } from "@/lib/axios";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Plaid } from "plaid-link";
import ldGet from "lodash/get";
import { LDFlagValue } from "@launchdarkly/node-server-sdk";
import { Confirm } from "@/mixins/Confirm";
import { Extension } from "@/mixins/Extension";
import { Introspection } from "@/mixins/Introspection";
import { loadScriptAsync } from "@/util";
import { PERMISSION } from "@/constants";
import {
  bankStore,
  eventStore,
  subscriptionStore,
  userStore,
  featureStore,
  fundingStore,
} from "@/store";
import { AccountPurposes } from "@/types/User";
import { OnboardingSteps, onboardingStepName } from "@/types/Onboarding";
import { nextBankDay } from "@/lib/dates";
import { FeatureFlags } from "@/types/LaunchDarkly";

interface FundingBank {
  institution_id: string; // eslint-disable-line
  name?: string;
  logo?: string;
  width?: number;
}

@Component({})
export default class AddFunding extends Mixins(
  Confirm,
  Extension,
  Introspection
) {
  @Prop({ default: null }) bankAccount!: any;
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) mobile!: boolean;

  // general
  isLocalhost = /^localhost/.test(window.location.host);
  errorMessage = "";
  plaidErrorMessage = "";
  submitted = false;
  submitTimeout: null | number = null;

  // loading
  loading = false;
  loadingText = "";
  loadingTextTimer: null | number = null;
  loadingTextOptions = [
    "Securely connecting to bank",
    "This may take a few minutes...",
    "Encrypting Data",
    "Please Wait",
    "Loading",
  ];
  // TODO: type this & update the type in bankStore.actions.addManually
  account: { [key: string]: any } = { bank: "" };
  isYellowPath = false;

  // If user has alternate funding permission, then allow them to chose routing/account
  // or bank (via plaid). Otherwise Plaid (bank) is their only choice.
  source = {
    type: userStore.getters.hasPermission(PERMISSION.ALTERNATE_FUNDING)
      ? ""
      : "bank",
  };

  // Bank Search
  bankSearchResults: FundingBank[] = [];
  bankSearchPending = false;
  bankSearchData = {
    keyword: "",
  };
  doSearchBanks = 0;

  // Bank Selection
  estimatedDepositArrival = "";

  // Plaid
  longTailOpening = false;
  plaid = {
    bankAccounts: [],
  };
  plaidLinkLoaded = false;
  plaidInterval = 0;
  plaidHandler: Plaid.LinkHandler | null = null;

  // Bank Account List
  bankAccounts = [];

  // Durbin
  durbinExemptBinsAreEnabled = false;

  // Alternate funding
  hasAlternateFundingRequestError = false;
  alternateFundingRequestErrorMessage = "";

  // Alt funding email CTA
  showAltFundingEmailCta = false;

  // Use new Plaid Flow
  usePlaidFlow2024 = false;

  // Direct bank account calls to v2 endpoints
  useMicrodepositV2 = false;

  get user() {
    return userStore.getters.currentUser;
  }

  get currentPage(): string {
    return this.$route.path;
  }

  get hasBankResults() {
    return this.bankSearchResults.length > 0;
  }

  get showAlternateFunding() {
    return this.source.type === "routing" && !this.loading;
  }

  get showManualBankConnectionLink() {
    return false;
  }

  get showBankSelect() {
    return (
      !this.loading &&
      this.plaid.bankAccounts.length === 0 &&
      this.source.type === "bank" &&
      this.account.bank.length === 0
    );
  }

  bankImage(bank: FundingBank) {
    return bank.name
      ? `/assets/images/banks/${bank.name.toLowerCase()}@2x.png`
      : "";
  }

  selectOnlineBank() {
    if (this.mobile) {
      // if mobile, redirect to plaid link to be handled by the app
      this.$router.push("/signup/funding-plaid");
      return;
    }
    this.source.type = "bank";
  }

  // Reset Scroll Between Sub-States
  @Watch("account.bank")
  scrollTop() {
    $("body").scrollTop(0);
  }

  created() {
    if (!this.fullpage) {
      this.$piwik.trackEvent("Modal", "View", {
        name: onboardingStepName(OnboardingSteps.ADD_BANK),
      });
    }

    // Add the Plaid Link initialize script (if it doesn't already exist) and we're not on mobile
    if (!document.getElementById("plaid-link") && !this.mobile) {
      loadScriptAsync(
        "plaid-link",
        "https://cdn.plaid.com/link/v2/stable/link-initialize.js"
      );
    }

    this.runAllIntrospectionChecks();

    // If a bank is passed in, set it in the scope so they don't have to select it manually.
    // This happens when they are fixing an existing account.
    if (this.bankAccount) {
      this.account.bank = this.bankAccount.bank;
      this.account.bankAccountID = this.bankAccount.bankAccountID;
    }

    // Wait until our dynamically added Plaid initializer <script> has loaded. Once loaded call
    // Plaid.create() on it, this will take another few moments to load before we can call
    // plaidHandler.open() on it.
    const _plaidInitializeInterval = window.setInterval(() => {
      if (typeof window.Plaid?.create === "function") {
        window.clearInterval(_plaidInitializeInterval);
        this.setUpPlaid();
      }
    }, 250);

    // List added funding accounts
    bankStore.actions.list().then(({ data }) => {
      this.bankAccounts = data.bankAccountList;
    });

    featureStore.actions
      .fetchFlag({ id: FeatureFlags.DURBIN_EXEMPT_BINS })
      .then((value: LDFlagValue) => {
        this.durbinExemptBinsAreEnabled = value;
      });

    featureStore.actions
      .fetchFlag({ id: FeatureFlags.EXISTING_VISA_DEBIT_USERS })
      .then((value: LDFlagValue) => {
        this.showAltFundingEmailCta = value;
      });

    featureStore.actions
      .fetchFlag({ id: FeatureFlags.PLAID_FLOW_2024 })
      .then((value: LDFlagValue) => {
        this.usePlaidFlow2024 = value;
      });

    featureStore.actions
      .fetchFlag({ id: FeatureFlags.MICRODEPOSIT_V2_ENDPOINTS })
      .then((value: LDFlagValue) => {
        this.useMicrodepositV2 = value;
      });
  }

  beforeDestroy() {
    if (this.loadingTextTimer) {
      window.clearInterval(this.loadingTextTimer);
    }
    this.$cookies.remove("plaidLinkToken");
    this.$cookies.remove("selectedBank");
  }

  getBankIconFromInstID(instID: string) {
    return bankStore.getters.getBankIconFromInstID({ instID, src: true });
  }

  cycleLoadingText() {
    if (this.loadingTextTimer) {
      window.clearInterval(this.loadingTextTimer);
    }

    this.loadingText = this.loadingTextOptions[0];
    this.loadingTextTimer = window.setInterval(() => {
      const loadingTextIndex = this.loadingTextOptions.indexOf(
        this.loadingText
      );
      this.loadingText =
        this.loadingTextOptions[
          (loadingTextIndex + 1) % this.loadingTextOptions.length
        ];
    }, 2500);
  }

  _addBankHandler(data: any) {
    if (data.bankAccountID) {
      // We've detected that this is really just fixing an existing account so set the
      // stateParams as if we clicked 'fix account'
      this.bankAccount = { bankAccountID: data.bankAccountID };
    }

    if (data.accounts) {
      const { accounts } = data;

      const { verificationNeeded = false } = data;
      if (verificationNeeded) {
        this.isYellowPath = true;
      }

      if (accounts.length === 0) {
        return this.confirm(
          "There don't seem to be any bank accounts associated with this login",
          {
            title: "Uh oh!",
            okOnly: true,
            okTitle: "Okay",
          }
        );
      }

      // If the user doesn't have any previous bank accounts
      // we know this will be the default account and we
      // can attemp to check if there's an outstanding balance
      if (this.user?.bankAccountList.length === 0) {
        subscriptionStore.actions.chargeOutstandingBalance();
      }

      if (this.bankAccount) {
        // Fixing an account so just go back to funding.
        return this.$router.push({ name: "account" });
      }

      if (accounts.length === 1) {
        this.completeBankSetup();
      } else {
        // Need to show a list of accounts so they can pick their default
        this.plaid.bankAccounts = accounts;
      }

      // At this point they've added a connection with one or more bank accounts
      bankStore.actions.postConnectionHooks();
    }
  }

  preSubmit() {
    // Prevent double submitting
    if (this.loading) return false;

    this.submitted = true;
    this.errorMessage = "";

    if (this.submitTimeout) window.clearTimeout(this.submitTimeout);
    this.submitTimeout = window.setTimeout(() => {
      this.submitted = false;
    }, 10000);

    this.loading = true;
    this.cycleLoadingText();
    return true;
  }

  setUpPlaid() {
    let linkToken = this.$cookies.get("plaidLinkToken");
    const _setUpPlaid = (receivedRedirectUri?: string) => {
      this.plaidHandler = window.Plaid?.create({
        env: this.isLocalhost ? "sandbox" : "production",
        token: linkToken,
        clientName: "Privacy.com",
        receivedRedirectUri: receivedRedirectUri || null,
        product: ["auth"],
        longtail: true,
        apiVersion: "v2",
        selectAccount: true,
        countryCodes: ["US"],

        onLoad: () => {
          // The Link module finished loading.
          const selectedBank = this.$cookies.get("selectedBank");
          if (selectedBank) {
            // if we have a stored bank, this is an oauth redirect
            // eslint-disable-next-line
            this.selectBank({ institution_id: selectedBank });
          }
          this.plaidLinkLoaded = true;
        },
        onSuccess: (publicToken: string, metadata: any) => {
          // remove OAuth cookies
          this.$cookies.remove("plaidLinkToken");
          this.$cookies.remove("selectedBank");
          this.loading = true;
          this.cycleLoadingText();
          const {
            account_id: plaidAccountID,
            institution: { institution_id: institutionID, name: bankName },
          } = metadata;
          // Plaid adds `overflow: hidden` to the body
          // and there seems to be a bug where it's not
          // removed. We need the timeout to drop it to
          // the bottom of the event loop
          setTimeout(() => {
            document.body.removeAttribute("style");
          });

          const args = {
            publicToken,
            plaidAccountID,
            institutionID,
            bankName,
          };
          const method = this.usePlaidFlow2024
            ? fundingStore.actions.createPlaidBankAccount
            : bankStore.actions.addLongTail;

          method(args)
            .then((response) => {
              this.loading = false;
              this._addBankHandler(response.data);
            })
            .catch((err: AxiosError<{ message?: string }>) => {
              this.loading = false;
              if (err.response?.data) {
                const message = err.response.data.message || "";
                this.confirm(message, {
                  title: "Error",
                  okOnly: true,
                  okTitle: "OK",
                });
                this.errorMessage = message;
              }
            });
        },
        onExit: (err: Error | null, metadata: Plaid.OnExitMetaData) => {
          // Set this to false just to be sure
          this.longTailOpening = false;
          this.loading = false;

          // Plaid adds `overflow: hidden` to the body
          // and there seems to be a bug where it's not
          // removed. We need the timeout to drop it to
          // the bottom of the event loop
          setTimeout(() => {
            document.body.removeAttribute("style");
          });

          // Log this server side
          if (err) {
            eventStore.actions.error({
              type: "plaid",
              metadata,
              err,
            });
          } else {
            eventStore.actions.info({
              type: "plaid",
              metadata,
            });
          }
          if (
            this.bankAccounts.length === 0 &&
            !subscriptionStore.getters.planRequiresOrganization
          ) {
            this.$emit("complete-step", OnboardingSteps.ADD_CARD_REROUTED);
          }
        },
        onEvent: (eventName: string, metadata: Plaid.OnEventMetaData) => {
          eventStore.actions.info({
            type: "plaid",
            eventName,
            metadata,
          });
        },
      } as unknown as Plaid.CreateConfig);
      // @types/plaid-link is missing the `receivedRedirectUri` param,
      // so convert the config to make it work
    };
    if (linkToken?.length > 0) {
      // if reinitializing plaid from Oauth redirect,
      // reuse link token and pass in receivedRedirectUri
      const { href } = window.location;
      const receivedRedirectUri = href.includes("oauth_state_id")
        ? href
        : undefined;
      _setUpPlaid(receivedRedirectUri);
    } else {
      bankStore.actions
        .createLinkToken()
        .then((response) => {
          linkToken = ldGet<any, string>(response, "data.link_token");
          this.$cookies.set("plaidLinkToken", linkToken);
          _setUpPlaid();
        })
        .catch(() => {
          this.plaidErrorMessage =
            "We're having trouble connecting to the bank network right now, please try again later.";
        });
    }
  }

  isBrokenBank(bank: FundingBank) {
    // Some institutions are basically always down with Plaid.  If they select one of these,
    // then just automatically direct them to AF instead of even trying to go through Plaid.
    // **XXX: Similar logic in server/api/bank/bank.controller.js
    // const BROKEN_BANKS = ['capone', 'capone360'];
    const BROKEN_BANKS: string[] = [];
    return BROKEN_BANKS.indexOf(bank.institution_id) !== -1;
  }

  selectBank(bank: FundingBank) {
    if (this.isBrokenBank(bank)) {
      // Load Alternate Funding flow
      this.source.type = "routing";
      return;
    }

    const _hasPlaidLoaded = () => {
      return (
        this.plaidLinkLoaded || typeof this.plaidHandler?.open === "function"
      );
    };

    this.longTailOpening = true;
    // So Plaid's link lib onLoad callback is never fired if the link module hasn't already been
    // cached. So we need to wait for Plaid's link module to load before calling a function on it
    if (_hasPlaidLoaded()) {
      (this.plaidHandler as any).open(bank.institution_id);
      this.$cookies.set("selectedBank", bank.institution_id);
      window.setTimeout(() => {
        this.longTailOpening = false;
      }, 1000);
    } else {
      if (this.plaidInterval) {
        window.clearInterval(this.plaidInterval);
      }
      this.plaidInterval = window.setInterval(() => {
        if (_hasPlaidLoaded() && this.plaidHandler) {
          window.clearInterval(this.plaidInterval);
          this.plaidHandler.open();
          window.setTimeout(() => {
            this.longTailOpening = false;
          }, 1000);
        }
      }, 100);
    }
  }

  exitRoutingEntry() {
    this.source.type = "";
  }

  validateABA() {
    if (!this.account.routingNumber) return;
    this.submitted = true;
    bankStore.actions
      .validateRoutingNumber(this.account.routingNumber)
      .catch((err: AxiosError<{ message?: string }>) => {
        this.loading = false;
        if (err.response?.data.message) {
          this.errorMessage = err.response?.data.message;
        }

        if (err.response?.status === 403) {
          // Banned bank account (Green Dot, MetaBank, etc)
          this.account.routingNumber = "";
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  addBankAccountNumbers() {
    if (!this.preSubmit()) {
      return;
    }

    this.loading = true;

    // Routing Number is 9 digits long
    if (!/^[0-9]{9}$/.test(this.account.routingNumber)) {
      this.loading = false;
      this.errorMessage =
        "Please re-check the routing number and ensure it is correct.";
      return this.errorMessage;
    }

    // Just make sure the account number is actual digits
    if (!/^[0-9]{4,17}$/.test(this.account.accountNumber)) {
      this.loading = false;
      this.errorMessage =
        "Please re-check the account number and ensure it is correct.";
      return this.errorMessage;
    }

    const actionPromise = this.useMicrodepositV2
      ? bankStore.actions.addManuallyV2(this.account)
      : bankStore.actions.addManually(this.account);

    actionPromise
      .then((response) => {
        this.loading = false;
        this.source.type = "microDepositClarification";

        if (response.data?.bankAccount?.length > 0) {
          bankStore.mutations.setConfirmingBank(response.data.bankAccount[0]);
        }

        // Tell them when they should expect to see the deposits
        this.estimatedDepositArrival = nextBankDay();
      })
      .catch((err: AxiosError<{ message?: string }>) => {
        this.loading = false;
        if (err.response?.data.message) {
          this.errorMessage = err.response?.data.message;
        }
      });
  }

  completeBankSetup() {
    userStore.mutations.updateCurrentUser({
      verificationNeeded: this.isYellowPath || false,
      hasFundingSource: true,
      hasHadAnyFundingSource: true,
    });

    if (this.user?.accountPurpose !== AccountPurposes.BUSINESS) {
      this.$emit("complete-step", OnboardingSteps.SUCCESS_BANK);
    } else if (this.user?.accountPurpose === AccountPurposes.BUSINESS) {
      // Commercial charge terms flow - notify possible YP later on
      this.$emit("complete-step", OnboardingSteps.SUCCESS_BANK);
    } else if (this.isYellowPath) {
      // Deprecated flow
      this.$emit("complete-step", OnboardingSteps.ADDITIONAL_VERIFICATION);
    } else {
      this.$emit("complete-step");
    }
  }

  searchBanks() {
    this.bankSearchPending = true;

    // Cancel search timeout
    window.clearTimeout(this.doSearchBanks);

    // Clear search immediately on empty string
    if (!this.bankSearchData.keyword) {
      this.resetBankSearch();
      // After .5s wait, search banks
    } else {
      this.doSearchBanks = window.setTimeout(() => {
        bankStore.actions
          .search(this.bankSearchData.keyword)
          .then((response) => {
            this.bankSearchResults = response.data;
          })
          .finally(() => {
            this.bankSearchPending = false;
          });
      }, 500);
    }
  }

  resetBankSearch() {
    this.bankSearchResults = [];
    this.bankSearchPending = false;
    this.bankSearchData.keyword = "";
  }

  resetForm() {
    this.submitted = false;
  }

  requestManualBankConnection() {
    this.hasAlternateFundingRequestError = false;
    bankStore.actions
      .requestAlternateFunding()
      .then(() => {
        userStore.mutations.updateCurrentUser({
          hasPendingAlternateFundingRequest: true,
        });
      })
      .catch(() => {
        this.hasAlternateFundingRequestError = true;
        this.alternateFundingRequestErrorMessage =
          "Something went wrong. Please try again later.";
      });
  }

  closeManualBankConnectionModal() {
    this.$bvModal.hide("onboarding-modal");
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/animations.scss";
@import "../../assets/styles/mixins.scss";

.add-funding {
  width: 21.75rem; // match BaseForm width
  margin: auto;
  padding-top: 1.25rem;
  max-width: unset;
  @media #{$media-phone} {
    max-width: 100%;
  }
}

.selection {
  text-align: center;
  font-weight: bold;
  margin: 10px;
  padding: 25px 20px;
  border: 2px solid $gray-100;
  border-radius: $border-radius;
  cursor: pointer;
  background-color: transparent;
  transition: background $duration-shorter;

  &:hover {
    background-color: $gray-100;
  }

  img {
    display: block;
    margin: 0 auto 10px;
  }

  .subtitle {
    font-size: 14px;
    color: $gray-600;
    font-weight: normal;
  }
}

h1,
::v-deep .modal-body h1 {
  font-family: $font-stack-wes-fy;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
  line-height: 1.4;
  color: $gray-800;
}

.blurb {
  text-align: center;
}

.plaid-connect-error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 250px;
  background-color: $gray-100;
  border-radius: $border-radius;
  text-align: center;
}

.loading-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  text-align: center;
}

.micro-deposit-info,
.micro-deposit-estimate {
  text-align: center;
}

a.-standalone {
  display: block;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.bank {
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  height: 60px;
  border-bottom: 2px solid $gray-100;
  cursor: pointer;

  &.-empty {
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    background-color: $gray-100;
  }

  > .img > img {
    display: block;
    height: auto;
    width: 20px;
  }

  > .name {
    flex: 1;
    margin-left: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:last-child {
    border-bottom: none;
  }
}
.no-results {
  padding: 40px 20px 20px;
  text-align: center;
}

.popular-banks {
  margin: auto;
  max-width: 570px;

  h3 {
    @include type-small-caps-10;

    margin: 20px 0 20px 0;
    color: $gray-800;
  }

  .select-bank {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 570px;

    .bank-container {
      display: flex;
      flex-basis: 50%;
      height: 90px;
      border-top: 1px solid $gray-100;
      border-bottom: 1px solid $gray-100;

      .bank {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 10px);
        margin: 5px;
        width: 100%;
        border-radius: 4px;

        img {
          padding: 5px;
          border-radius: 4px;
        }

        &:hover {
          background: $gray-100;
          cursor: pointer;
        }
      }

      &:nth-child(even) {
        border-left: 1px solid $gray-100;
      }

      &:nth-child(odd) {
        border-right: 1px solid $gray-100;
      }

      &.last-row {
        border-bottom: none;
      }
    }

    @media #{$media-phone} {
      border-right: 0;
      border-left: 0;
    }
  }
}

.banking {
  position: relative;
  height: 416px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 0 0 2px $gray-100;
  border-radius: $border-radius;
}

::v-deep .banking .fields > label {
  position: relative;
  clear: both;
  display: block;
  overflow: hidden;
  margin-bottom: 0;

  &.search {
    position: relative;
    border-bottom: 1px solid $gray-100;

    input.text {
      padding: 18px 70px 18px 50px;
      width: 100%;
      background: none;
      border: none;
      font-size: 14px;
      line-height: 20px;
      resize: none;
      outline: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 19px;
      left: 20px;
      display: block;
      height: 20px;
      width: 20px;
      background: url("/assets/images/icons/magnifier-20.svg") center no-repeat;
      pointer-events: none;
    }

    &.-loading::after {
      @include animation(
        spin,
        $iteration-count: infinite,
        $timing-function: linear
      );

      content: "";
      position: absolute;
      top: 0;
      right: 20px;
      bottom: 0;
      display: block;
      margin: auto;
      height: 30px;
      width: 30px;
      background: url("/assets/images/onboarding/loading-spinner.png") center
        no-repeat;
      background-size: contain;
    }
  }

  &.results {
    position: absolute;
    top: 57px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    border-top: 1px solid $gray-100;
  }
}

.clear-search {
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  display: block;
  margin: auto;
  height: 30px;
  width: 30px;
  background: url("/assets/images/icons/cross-20.svg") center no-repeat;
  background-color: fade-out($gray-800, 0.95);
  border-radius: 100px;
  cursor: pointer;

  &:hover {
    background-color: fade-out($gray-800, 0.8);
  }
}

.manual-bank-connection {
  display: grid;
  padding: 0.5rem;
}

.alt-funding-email-cta {
  text-align: left;
  font-size: 12px;
  padding: 24px;
  margin-top: 40px;
  background: $gray-100;
  border-radius: 8px;
}
</style>
